import { Drawer, IconButton, List } from "@material-ui/core";
import {
  ArrowBack as ArrowBackIcon,
  LibraryBooks as LibraryIcon
} from "@material-ui/icons";
import { useTheme } from "@material-ui/styles";
import classNames from "classnames";
import React, { useEffect, useState } from "react";
import { useTranslation } from 'react-i18next';
import { FcApproval, FcAutomatic, FcBusinessman, FcConferenceCall, FcCurrencyExchange, FcDataSheet, FcDebt, FcDecision, FcDepartment, FcDocument, FcHome, FcLibrary, FcList, FcVoicePresentation } from "react-icons/fc";
import { withRouter } from "react-router-dom";
import NewIcons from "../../pages/icons/NewIcons";
import axios from '../../utils/axios';
import { getPermissionArray } from "../../utils/strings/userString";

// styles
import useStyles from "./styles";


// components
import SidebarLink from "./components/SidebarLink/SidebarLink";

// context
import {
  toggleSidebar, useLayoutDispatch, useLayoutState
} from "../../context/LayoutContext";




function Sidebar({ location }) {
  const permissionArray = getPermissionArray();
  const [isDashboard, setIsDashboard] = useState('cmsf')
  var classes = useStyles();
  var theme = useTheme();
  
  const { t } = useTranslation();

  // global
  var { isSidebarOpened } = useLayoutState();
  var layoutDispatch = useLayoutDispatch();

  // local
  var [isPermanent, setPermanent] = useState(true);
  var [permissionsOfUser, setPermissionsOfUser] = useState([{}]);

  const user = localStorage.getItem('user-name')
  const userFormatted = JSON.parse(user);
  const userType = userFormatted?.userTypes;
  
      

  const userpermission = localStorage.getItem("permission")

 
  /* 1 */ var canAccessClaimMyDividend = userpermission.includes("Claim-My-Dividend-Read")
  /* 2 */ var canAccessInwardAdd = userpermission.includes("Inward-Dividend-add-Write")
  /* 3 */ var canAccessInwardList = permissionArray.includes("Inward-Dividend-list-Read")
  /* 4 */ var canAccessInwardDivWiseInvestorList = userpermission.includes("Inward-Dividend-list-sub-investor-details")
  /* 5 */ var canAccessInwardDivWiseEdit = userpermission.includes("Inward-Dividend-list-sub-edit")
  /* 6 */ var canAccessInwardDivWiseDelete = userpermission.includes("Inward-Dividend-list-sub-delete")
  /* 7 */ var canAccessInwardDivWiseFileUpload = userpermission.includes("Inward-Dividend-list-sub-file-upload")

  /* 8 */ var canAccessInvestorInfo = permissionArray.includes("Investors-Information-Read")
  /* 9 */ var canAccessIssuerAdd = userpermission.includes("Issuer-add-Read")
  /* 10 */ var canAccessIssuerList = userpermission.includes("Issuer-list-Read")
  /* 11 */ var canEditIssuerInfo = userpermission.includes("Issuer-list-sub-edit")
  /* 12 */ var canDeleteAnIssuer = userpermission.includes("Issuer-list-sub-delete")

  /* 13 */ var canAccessClaimListIssuer = userpermission.includes("Claim-List-Issuer-Read")
  /* 14 */ var canAccessClaimListCMSF = userpermission.includes("Claim-List-CMSF-Read")
  /* 15 */ var canAccessClaimListInvestor = userpermission.includes("Claim-List-Investor-Read")

  /* 16 */ var canAccessCMSFDashboard = userpermission.includes("Dashboard-management-Read")
  /* 17 */ var canAccessPolicyDashboard = userpermission.includes("Dashboard-policy-Read")
  /* 18 */ var canAccessInvestorDashboard = userpermission.includes("Dashboard-investor-Read")
  /* 24 */ var canAccessHRLeaveDashboard = userpermission.includes("leave-HR-dashboard")
  /* 25 */ var canAccessN1LeaveDashboard = userpermission.includes("leave-N1-dashboard")

  

//  console.log(canAccessClaimMyDividend,canAccessInwardAdd,canAccessInwardList,canAccessInvestorInfo,canAccessIssuerAdd,canAccessIssuerList,canAccessClaimListIssuer,canAccessClaimListCMSF,canAccessClaimListInvestor,canAccessCMSFDashboard,canAccessPolicyDashboard,canAccessInvestorDashboard)
        

  const getUsersPermissionData = () =>{
    // if (userId){
    axios.get('permissions/menu-lists')
    .then((res) =>{
        setPermissionsOfUser(res?.data?.payload)
    })
    .catch((err) =>{
      console.log(err)
    })
  // }
}


  useEffect(()=>{
    getUsersPermissionData();
  },[]);

// console.log(permissionsOfUser)

const IconComponent = ({ iconName, style }) => {
  const SelectedIcon = NewIcons[iconName];

  if (!SelectedIcon) {
      return null; // Return null if iconName is not valid
  }

  // Render the selected icon as a React component
  return <SelectedIcon style={style} />;
};

const structure2 = permissionsOfUser?.map(obj => {
  let childArray = [];

  let parentLink = '';
  let childLink = '';
  if (obj.parentid !== 0) {
    
    const parent = permissionsOfUser.find(parentObj => obj.parentid === parentObj.id);
    if (parent) {
      parentLink = parent.link;
      // console.log(obj.id)
      // console.log(parent.link)
    }
  }
 
  if (obj.child!==undefined){
    if(obj.child.length===0){
      // console.log(obj.id)
    }
    else{
      childArray=obj.child;
      // console.log(childArray)
      for(let i=0;i<childArray.length;i++){
        // console.log(`${childArray[i]}`)
        let j = childArray[i]
        j.icon=<IconComponent iconName={obj.icon} style={{ fontSize: '1.8rem' }}/>
        // console.log(j)
      //  console.log(permissionsOfUser.find(obj => obj.id === j ));
       
      }
    }
  }
  return {
    id: obj.id,
    label: obj.label,
    // child:obj.child,
    link: obj.link,
    icon:  <IconComponent iconName={obj.icon} style={{ fontSize: '1.8rem' }}/>,
    // child: obj.child
    // routePath: obj.routePath,
    // parentid:obj.parentid,
    child:childArray
    
    // React.createElement(NewIcons[obj.icon], { style: { fontSize: '1.8rem' } })
  };
});


// console.log (structure2)
// console.log (childArray)



  const structure = [
    // {
    //   id: 0,
    //   label: t('Dashboard.title'),
    //   link: "/app/dashboard",
    //   icon: <HomeIcon />,
    //   child : userType[0] === 'Investor' ? [
    //     { label: t('Dashboard.title3'), link: "/app/dashboard/investors" },
    //   ] : userType[0] === 'Issuer' ? [
    //     { label: t('Dashboard.title1'), link: "/app/dashboard/policy-level" },
    //   ] : userType[0] === 'Admin_CMSF' ? [
    //     { label: t('Dashboard.title2'), link: "/app/dashboard/cmsf-management" },
    //   ] : ""
    // },

    ( canAccessCMSFDashboard == true ? { id: 0, label: t('Dashboard.title'), link: "/app/dashboard/cmsf-management", 
    // icon: <HomeIcon />
     icon: <FcHome style={{fontSize:'1.8rem'}}/> }:false),
    ( canAccessPolicyDashboard == true ? { id: 16, label: t('Dashboard.title'), link: "/app/dashboard/policy-level", 
    // icon: <HomeIcon /> 
    icon: <FcHome style={{fontSize:'1.8rem'}}/> 
  }:false),
    ( canAccessInvestorDashboard == true ? { id: 17, label: t('Dashboard.title'), link: "/app/dashboard/investors", 
    icon: <FcHome style={{fontSize:'1.8rem'}}/> 
  }:false),
  
    // (canAccessCMSFDashboard),
    //   { id: 0, label: t('Dashboard.title'), link: "/app/dashboard/cmsf-management",  icon: <HomeIcon /> },
    // (canAccessPolicyDashboard),
    //   { id: 15, label: t('Dashboard.title'), link: "/app/dashboard/policy-level"  },
    // (canAccessInvestorDashboard),
    //   { id: 16, label: t('Dashboard.title'), link: "/app/dashboard/investors"  },
  //----------------------------------------------------------------
    //  ( canAccessPolicyDashboard === true ? 
    //   { id: 0, label: t('Dashboard.title'), link: "/app/dashboard/policy-level",  icon: <HomeIcon />, } :

    //   canAccessInvestorDashboard === true ? 
    //   { id: 0, label: t('Dashboard.title'), link: "/app/dashboard/investors",  icon: <HomeIcon />, } :  false),


    (canAccessInwardAdd === true || canAccessInwardList === true ?
    {
      id: 1,
      label: t('inwardDividend.titleSideBar'),
      link: "/app/inward-dividend",
      // icon: <MonetizationOnIcon />,
      icon: <FcCurrencyExchange style={{fontSize:'1.8rem'}}/>,
      child: 
        canAccessInwardAdd === true  && canAccessInwardList === true ?
        [
          { label: t('inwardDividend.titleSideBarListOne'), link: "/app/inward-dividend/new-add" },
          { label: t('inwardDividend.titleSideBarListTwo'), link: "/app/inward-dividend/list" },
          
        ] :
        canAccessInwardAdd === true  && canAccessInwardList === false ?
          [{ label: t('inwardDividend.titleSideBarListOne'), link: "/app/inward-dividend/new-add" }] : 
        [{ label: t('inwardDividend.titleSideBarListTwo'), link: "/app/inward-dividend/list" } ]
      
    } :  false),

    ( canAccessInvestorInfo == true ? { id: 2, label: t('investorsInformation.titleSideBar'), link: "/app/investor-informations", 
    // icon: <GroupIcon />
    icon: <FcConferenceCall  style={{fontSize:'1.8rem'}}/> 
    }:false),

    
    (canAccessClaimMyDividend === true?{ id: 3, label: t('searchDividend.title'), link: "/app/search-dividend", 
    // icon: <FindInPage />
    // icon: <FcSearch style={{fontSize:'1.8rem'}}/>
    icon: <FcDebt   style={{fontSize:'1.8rem'}}/>,
  }:false),




    (canAccessClaimListCMSF === true  || canAccessClaimListIssuer === true  ||  canAccessClaimListInvestor === true ?
    {
      id: 4,
      label: t('claimSettlement.title'),
      link: "/app/lodged-claim/list",
      // icon: <ListAltIcon />,
       icon:  <FcList style={{fontSize:'1.8rem'}}/>,
      child: 
      canAccessClaimListCMSF === false && canAccessClaimListIssuer ===false && canAccessClaimListInvestor === true?[
        { label: t('claimListInvestors.titleSideBar'), link: "/app/claim-settlement/claim/investors/list" }
      ]:
      canAccessClaimListCMSF === false && canAccessClaimListIssuer ===true && canAccessClaimListInvestor === false?[
        { label: t('claimListIssuer.titleSideBar'), link: "/app/claim-settlement/claim/issuer/list" }
      ]:
      canAccessClaimListCMSF === false && canAccessClaimListIssuer ===true && canAccessClaimListInvestor === true?[
        { label: t('claimListInvestors.titleSideBar'), link: "/app/claim-settlement/claim/investors/list" },
        { label: t('claimListIssuer.titleSideBar'), link: "/app/claim-settlement/claim/issuer/list" }
      ]:
      canAccessClaimListCMSF === true && canAccessClaimListIssuer ===false && canAccessClaimListInvestor === false?[
        { label: t('claimListCmsf.titleSideBar'), link: "/app/claim-settlement/claim/cmsf/list" }
      ]:
      canAccessClaimListCMSF === true && canAccessClaimListIssuer ===false && canAccessClaimListInvestor === true?[
        { label: t('claimListCmsf.titleSideBar'), link: "/app/claim-settlement/claim/cmsf/list" },
        { label: t('claimListInvestors.titleSideBar'), link: "/app/claim-settlement/claim/investors/list" }
      ]:
      canAccessClaimListCMSF === true && canAccessClaimListIssuer ===true && canAccessClaimListInvestor === false?[
        { label: t('claimListCmsf.titleSideBar'), link: "/app/claim-settlement/claim/cmsf/list" },
        { label: t('claimListIssuer.titleSideBar'), link: "/app/claim-settlement/claim/issuer/list" },
      ]: 
      canAccessClaimListCMSF === true && canAccessClaimListIssuer ===true && canAccessClaimListInvestor === true?[
        { label: t('claimListCmsf.titleSideBar'), link: "/app/claim-settlement/claim/cmsf/list" },
        { label: t('claimListIssuer.titleSideBar'), link: "/app/claim-settlement/claim/issuer/list" },
        { label: t('claimListInvestors.titleSideBar'), link: "/app/claim-settlement/claim/investors/list" },
      ] : false
      
    } : false),

    (userType[0] === 'Super_Admin' || userType[0] === 'Admin_CMSF'?
    {
      id: 18,
      label: "Settlement" ,
      link: "/app/settlement",
      // icon: <PermDataSettingIcon />,
      icon: < FcApproval style={{fontSize:'1.8rem'}}/>,
      child: [
        { label: "Initial Dividend Settlement Entry" , link: "/app/settlement/dividend-settlement" },
        { label: "Ready to Settle Dashboard" , link: "/app/settlement/ready-to-settled-dividends" },
        { label: "Date Wise Compliance Report" , link: "/app/settlement/settled-dividends" },
        { label: "Settlement Data Upload Report" , link: "/app/settlement/data-uploaded-by-employee" },
      ],
    }: false),

    


    (canAccessIssuerAdd === true || canAccessIssuerList === true || userType[0] === 'Issuer'?
    { 
      id: 5,
      label: t('Issuer.sideBarMainTitle'),
      link: "/app/issuer/list",
      //icon: <LocationCityIcon />,
      icon: <FcDepartment style={{fontSize:'1.8rem'}}/>, 
      child:  canAccessIssuerAdd === true  && canAccessIssuerList === false ?[
        { label: t('Issuer.sideBarAddTitle'), link: "/app/issuer/new-add" },
        { label: 'Define Div Year', link: "/app/issuer/yearDefine" },
      ]:
      canAccessIssuerAdd === false  && canAccessIssuerList === true ?[
        { label: t('Issuer.sideBarListTitle'), link: "/app/issuer/list" },
        { label: 'Define Div Year', link: "/app/issuer/yearDefine" },
      ]:
      canAccessIssuerAdd === true  && canAccessIssuerList === true ?[
        { label: t('Issuer.sideBarAddTitle'), link: "/app/issuer/new-add" },
        { label: t('Issuer.sideBarListTitle'), link: "/app/issuer/list" },
        { label: 'Define Div Year', link: "/app/issuer/yearDefine" },
      ]:
      canAccessIssuerAdd === false  && canAccessIssuerList === false && userType[0] === 'Issuer'?[
        { label: 'Define Div Year', link: "/app/issuer/yearDefine" },
      ] : false
    } : false ),

    // Add Bank-BO
    (userType[0] === 'Super_Admin' || userType[0] === 'Admin_CMSF'?
    {
      id: 15,
      label: t('BankBo.titleSideBar') ,
      link: "/app/add-receive-account",
      // icon: <PermDataSettingIcon />,
      icon: <FcLibrary  style={{fontSize:'1.8rem'}}/>,
      child: [
        { label: t('BankBo.titleSideBarListOne') , link: "/app/add-receive-account/bank" },
        { label: t('BankBo.titleSideBarListTwo') , link: "/app/add-receive-account/bo" },
      ],
    }: false),

    (userType[0] === 'Super_Admin' || userType[0] === 'Admin_CMSF'?
    {
      id: 21,
      label: "Accounts" ,
      link: "/app/ledger",
      icon: <FcDocument style={{fontSize:'1.8rem'}}/>,
      child: [
        { label: "Create Ledger" , link: "/app/ledger/create" },
        { label: "Pass Voucher" , link: "/app/voucher/create" },
        { label: "Loan / FDR Declare" , link: "/app/declare/LoanFDR" },
      ],
    }: false),

    (userType[0] != 'Investor'?
    {
      id: 19,
      label: "MIS" ,
      link: "/app",
      // icon: <PermDataSettingIcon />,
      icon: <FcDataSheet style={{fontSize:'1.8rem'}}/>,
      child:  userType[0] === 'Issuer'?[
        { label: t('Investor Report') , link: "/app/reportOfInvestorSearch" }, 
      ]:[       
        { label: t(`Issuers Summury Report`) , link: "/app/companySummaryReport" },
        { label: t('Issuer Year-wise Details Report') , link: "/app/companyStatusReport" },
        { label: t(`Issuer-wise Investors' Report`) , link: "/app/IssuerWiseInvestorReport" },
        { label: t('Investor Details') , link: "/app/reportOfInvestorSearch" }, 
        { label: t('Ledger-wise Investor Report') , link: "/app/LedgerWiseInvestorReport" },
        { label: t('Date wise Issuer Report') , link: "/app/dateWiseIssuerReport" },
        
          
        
      ],
    }: false),

    (userType[0] === 'Super_Admin' || userType[0] === 'Admin_CMSF'?
    {
      id: 20,
      label: "Employee" ,
      link: "/app/employee",
      icon: <FcBusinessman style={{fontSize:'1.8rem'}}/>,
      child: [
        { label: "Attendance" , link: "/app/employee-attendace" },
        canAccessHRLeaveDashboard ?{ label: "Holiday Declare" , link: "/app/holiday-declare" }:null,
        { label: "Leave Application" , link: "/app/leave-application" },
        canAccessN1LeaveDashboard ? { label: "Leave Approval Dashboard (Team Leader)" , link: "/app/leave-approval" }:null,
        canAccessHRLeaveDashboard ? { label: "Leave Approval Dashboard (HR)" , link: "/app/hr-leave-approval" }:null,
      ].filter(Boolean),
    }: false),
   

    (userType[0] === 'Super_Admin' ?
    {
      id: 6,
      label: t('Roles.sideBarMainTitle'),
      link: "/app/role",
      // icon:  <PermDataSettingIcon />,
       icon:  <FcAutomatic style={{fontSize:'1.8rem'}}/>,
      child: [
        { label: t('Roles.sideBarTitle1'), link: "/app/role" },
        { label: t('Roles.sideBarTitle2'), link: "/app/permissions" },
        { label: t('Roles.sideBarTitle3'), link: "/app/permission-types" },
      ],
    }: false),


    (userType[0] === 'Super_Admin' ?
    {
      id: 7,
      label: t('UserM.sideBarMainTitle'),
      link: "/app/user-role",
      // icon: <GroupAddIcon />,
      icon:  <FcAutomatic style={{fontSize:'1.8rem'}}/>,
      child: [
        // { label: t('UserM.sideBarTitle1'), link: "/app/user-role" },
        { label: t('UserM.sideBarTitle2'), link: "/app/user-permission" },
        { label: t('UserM.sideBarTitle3'), link: "/app/user-investor" },
        { label: t('UserM.sideBarTitle4'), link: "/app/user-issuer" },
        { label: t('UserM.sideBarTitle5'), link: "/app/user-cmsf" },
      ]
    }: false),
    (userType[0] === 'Super_Admin' || userType[0] === 'Admin_CMSF'?
    {
      id: 8,
      label: "System Maintainance" ,
      link: "/app/systemmaintainance",
      icon: <FcAutomatic style={{fontSize:'1.8rem'}}/>,
      child: [
        { label: "Undefined Cash/Stock Convert to Define (Full or Partial)" , link: "/app/systemmaintainance/undefineconverttodefine" },
        { label: "Issuer Wise Data Entry" , link: "/app/systemmaintainance/issuerWiseDataEntry" },
        { label: "Issuer wise undefined amount/stock increase" , link: "/app/systemmaintainance/undefineamountadd" },
      ],
    }: false),
    { id: 9, type: "divider" },
    { id: 10, type: "title", label: "" },
    { id: 11, label: t('sideBarBottom.Title1'), link: "https://cmsfbd.org/", icon: <LibraryIcon /> },
    { id: 12, label: t('sideBarBottom.Title2'), link: "https://cmsfbd.org/contact-us", 
    // icon: <SupportIcon /> },
    icon: <FcVoicePresentation style={{fontSize:'1.8rem'}}/> },
    { id: 13, label: t('sideBarBottom.Title3'), link: "https://cmsfbd.org/faq", 
    // icon: <FAQIcon /> },
    icon: <FcDecision style={{fontSize:'1.8rem'}} /> },
    // { id: 13, label: t('sideBarBottom.Title4')},
    { id: 14, type: "divider" },
   
  ];
 
  // link: `${window.open('https://cmsfbd.org')}`

  

  useEffect(function() {
    window.addEventListener("resize", handleWindowWidthChange);
    handleWindowWidthChange();
    return function cleanup() {
      window.removeEventListener("resize", handleWindowWidthChange);
    };
  });

  return (
    <Drawer
      variant={isPermanent ? "permanent" : "temporary"}
      className={classNames(classes.drawer, {
        [classes.drawerOpen]: isSidebarOpened,
        [classes.drawerClose]: !isSidebarOpened,
      })}
      classes={{
        paper: classNames({
          [classes.drawerOpen]: isSidebarOpened,
          [classes.drawerClose]: !isSidebarOpened,
        }),
      }}
      open={isSidebarOpened}
    >
      <div className={classes.toolbar} />
      <div className={classes.mobileBackButton}>
        <IconButton onClick={() => toggleSidebar(layoutDispatch)}>
          <ArrowBackIcon
            classes={{
              root: classNames(classes.headerIcon, classes.headerIconCollapse),
            }}
          />
        </IconButton>
      </div>
      <List className={classes.sidebarList}>
        {structure.map(link=> ( link?
          <SidebarLink
            key={link?.id}
            location={location}
            isSidebarOpened={isSidebarOpened}
            {...link}
          /> : false
          
        ))}
      </List>
      
    </Drawer>
  );

  function handleWindowWidthChange() {
    var windowWidth = window.innerWidth;
    var breakpointWidth = theme.breakpoints.values.md;
    var isSmallScreen = windowWidth < breakpointWidth;

    if (isSmallScreen && isPermanent) {
      setPermanent(false);
      toggleSidebar(layoutDispatch);
    } else if (!isSmallScreen && !isPermanent) {
      setPermanent(true);
      toggleSidebar(layoutDispatch);
    }
  }
  
}

export default withRouter(Sidebar);
