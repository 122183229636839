import { Box, IconButton, Link } from '@material-ui/core';
import Icon from '@mdi/react';
import classnames from "classnames";
import React, { useEffect } from "react";
import {
  Redirect, Route,
  Switch, withRouter
} from "react-router-dom";
import ClaimList from '../../pages/claimSettlement/claimList/ListInvestorsDetailsComponent';
// import SearchToClaimComponent from '../../pages/claimSettlement/lodgeCLaim/SearchToClaimComponent';
import SearchToClaimComponent from '../../pages/claimSettlement/lodgeCLaim2/SearchToClaimComponent';
import ListInvestorsInformationComponent from '../../pages/investorsInformation/list/ListInvestorsInformationComponent';
import ListInwardDividendComponent from "../../pages/inwardDividend/list/ListInwardDividendComponent";
import ViewInwordDividendComponent from '../../pages/inwardDividend/view/ViewInwardDividendComponent';
import ChangePassword from '../../pages/login/ChangePassword';
import CmsfUserCreate from '../../pages/userType/cmsf/add/CmsfUserCreate';
import EditInwordDividendComponent from './../../pages/inwardDividend/edit/EditInwardDividendComponent';
import CmsfLogo from './Footer/logo/cmsf.jpg';

//icons
import {
  mdiFacebook as FacebookIcon, mdiLinkedin
} from '@mdi/js';
import BaplcLogo from './Footer/logo/baplc.jpg';
import BbLogo from './Footer/logo/bb.png';
import BicmLogo from './Footer/logo/bicm.jpg';
import BsecLogo from './Footer/logo/BSEC.png';
import CcblLogo from './Footer/logo/ccbl.jpg';
import CdblLogo from './Footer/logo/cdbl.png';
import CseLogo from './Footer/logo/cse.png';
import DseLogo from './Footer/logo/dse.png';
import MofLogo from './Footer/logo/mof.jpg';
import NbrLogo from './Footer/logo/nbr.jpg';
// styles
import useStyles from "./styles";

// components
import Header from "../Header";
import Sidebar from "../Sidebar";

// pages
import Dashboard from "../../pages/dashboard";

// context
import { jwtDecode } from 'jwt-decode';
import { useHistory } from 'react-router-dom';
import { useLayoutState } from "../../context/LayoutContext";
import CreateLedger from '../../pages/accounts/ledger/CreateLedger';
import LoanFDRDeclaration from '../../pages/accounts/LoanFDRDeclare/LoanFDRDeclaration';
import CreateVoucher from '../../pages/accounts/voucher/CreateVoucher';
import BankToReceiveDividend from '../../pages/addRcvdDividendBankBo/bank/BankToReceiveDividend';
import BODetailsToReceiveDividend from '../../pages/addRcvdDividendBankBo/bo/BODetailsToReceiveDividend';
import BankInformations from '../../pages/bankInformation/BankInformations';
import ViewBankInfo from '../../pages/bankInformation/ViewBankInfo';
import ClaimListCmsf from '../../pages/claimListCmsf/ClaimListCmsf';
import ClaimViewCmsf from '../../pages/claimListCmsf/view/ClaimViewCmsf';
import ClaimListInvestors from '../../pages/claimListInvestors/ClaimListInvestors';
import ClaimViewInvestors from '../../pages/claimListInvestors/view/ClaimViewInvestors';
import ClaimListIssuer from '../../pages/claimListIssuer/ClaimListIssuer';
import ClaimViewIssuer from '../../pages/claimListIssuer/view/ClaimViewIssuer';
import EditLodgedClaimComponent from '../../pages/claimSettlement/edit/EditLodgedClaimComponent';
import ViewLodgedClaimComponent from '../../pages/claimSettlement/view/ViewLodgedClaimComponent';
import DashboardGeneral from '../../pages/dashboardGeneral/DashboardGeneral';
import DashboardInvestors from '../../pages/dashboardInvestors/DashboardInvestors';
import DashboardManagement from '../../pages/dashboardManagement/DashboardManagement';
import Attendance from '../../pages/employee/Attendance';

import HolidayDeclare from '../../pages/employee/HolidayDeclare';
import HrLeaveApprovalDashboard from '../../pages/employee/HrLeaveApprovalDashboard';
import LeaveApplication from '../../pages/employee/LeaveApplication';
import LeaveApprovalDashboard from '../../pages/employee/LeaveApprovalDashboard';

import ViewInvestorInfo from '../../pages/investorsInformation/view/ViewInvestorInfo';
import AddInwordDividendComponent from '../../pages/inwardDividend/add/AddInwordDividendComponent';
import ListInvestorsDetailsComponent from '../../pages/inwardDividend/investorsDetails/ListInvestorsDetailsComponent';
import ViewInvestor from '../../pages/inwardDividend/investorsDetails/view/ViewInvestor';
import AddIssuer from '../../pages/issuer/add/AddIssuer';
import DefineDivYearForm from '../../pages/issuer/defineIssuersDivYear/DefineDivYearForm';
import Edit from '../../pages/issuer/edit/Edit';
import IssuerDetail from '../../pages/issuer/list/IssuerDetail';
import ListOfIssuers from '../../pages/issuer/list/ListOfIssuers';
import LodgeClaimComponent from '../../pages/lodgeclaim/LodgeClaimComponent';
import CompanyStatus from '../../pages/newReports/companyStatusReport/CompanyStatus';
import CompanySummary from '../../pages/newReports/companySummaryReport/CompanySummary';
import dateWiseIssuerReport from '../../pages/newReports/dateWiseIssuerReport/dateWiseIssuerReport';
import ReportOfInvestorSearch from '../../pages/newReports/investorReport/ReportOfInvestorSearch';
import IssuerWiseInvestorR from '../../pages/newReports/issuerWiseInvestorReport/IssuerWiseInvestorR';
import ReportOfLedger from '../../pages/newReports/ledgerWiseReport/ReportOfLedger';
import Permission from '../../pages/pemissions/permission/Permission';
import PermissionTypes from '../../pages/pemissions/permissionTypes/PermissionTypes';
import Role from '../../pages/pemissions/role/Role';
import Profile from '../../pages/profile/Profile';
import ProfileEdit from '../../pages/profile/ProfileEdit';
import DividendSettlement from '../../pages/settlement/DividendSettlement';
import DataUploadedByOfficers from '../../pages/settlement/DividendSettlementReport/DataUploadedByOfficers';
import ReadyToSettled from '../../pages/settlement/ReadyToSettled';
import SettledDividend from '../../pages/settlement/SettledDividend';
import issuerWiseDataEntry from '../../pages/SystemMaintainance/issuerWiseDataEntry/issuerWiseDataEntry';
import IssuerWiseUndefineAmountIncrease from '../../pages/SystemMaintainance/IssuerWiseUndefineAmountIncrease/IssuerWiseUndefineAmountIncrease';
import UndefineConverttoDefine from '../../pages/SystemMaintainance/UndefineConvertToDefine/UndefineConverttoDefine';
import TrackClaim from '../../pages/trackclaim/TrackClaim';
import pageofUauth from '../../pages/un-authorized/pageofUauth';
import UserPermission from '../../pages/userManagement/userPermission/UserPermission';
import ListCmsfType from '../../pages/userType/cmsf/list/ListCmsfType';
import FilterTableComponent from '../../pages/userType/investor/list/FilterTableComponent';
import IssuerUserCreate from '../../pages/userType/issuer/add/IssuerUserCreate';
import ListIssuerType from '../../pages/userType/issuer/list/ListIssuerType';
import ClaimReport from '../../reports/ClaimReport/ClaimReport';
import CreateMenu from '../Sidebar/CreateMenu';


// import SearchToClaimComponent from '../../pages/claimSettlement/lodgeCLaim/SearchToClaimComponent';

//icons

// styles

// components

// pages

// context



function Layout(props) {
  var classes = useStyles();
  // const {userType} = useAuth()

  // global
  var layoutState = useLayoutState();
  const history = useHistory();
  const user = localStorage.getItem('user-name')
  const userFormatted = JSON.parse(user);
  const userType = userFormatted?.userTypes[0]
  localStorage.setItem(userType, userType)
  // console.log(userType)

  const token = localStorage.getItem("bearer-token");
  // console.log(localStorage);
  let decoded="";
  if(token)
  decoded = jwtDecode(token);

  useEffect(() => {
    const expirationTime = (decoded.exp * 1000) - 60000;
    if(Date.now() > expirationTime )
    {
      localStorage.removeItem("bearer-token");
      localStorage.removeItem("user-name");
      localStorage.removeItem("user-specific-name");
      localStorage.removeItem("user-id");
      localStorage.removeItem("user-email");
      history.push("/login");
    }
  
    
  
  }, [])
    
  const userpermission = localStorage.getItem("permission")
 
 
  /* 1 */ var canAccessClaimMyDividend = userpermission.includes("Claim-My-Dividend-Read")

  /* 2 */ var canAccessInwardAdd = userpermission.includes("Inward-Dividend-add-Write")
  /* 3 */ var canAccessInwardList = userpermission.includes("Inward-Dividend-list-Read")
  /* 4 */ var canAccessInwardDivWiseInvestorList = userpermission.includes("Inward-Dividend-list-sub-investor-details")
  /* 5 */ var canAccessInwardDivWiseEdit = userpermission.includes("Inward-Dividend-list-sub-edit")
  /* 6 */ var canAccessInwardDivWiseDelete = userpermission.includes("Inward-Dividend-list-sub-delete")
  /* 7 */ var canAccessInwardDivWiseFileUpload = userpermission.includes("Inward-Dividend-list-sub-file-upload")

  /* 8 */ var canAccessInvestorInfo = userpermission.includes("Investors-Information-Read")

  /* 9 */ var canAccessIssuerAdd = userpermission.includes("Issuer-add-Read")
  /* 10 */ var canAccessIssuerList = userpermission.includes("Issuer-list-Read")
  /* 11 */ var canEditIssuerInfo = userpermission.includes("Issuer-list-sub-edit")
  /* 12 */ var canDeleteAnIssuer = userpermission.includes("Issuer-list-sub-delete")

  /* 13 */ var canAccessClaimListIssuer = userpermission.includes("Claim-List-Issuer-Read")
  /* 14 */ var canAccessClaimListCMSF = userpermission.includes("Claim-List-CMSF-Read")
  /* 15 */ var canAccessClaimListInvestor = userpermission.includes("Claim-List-Investor-Read")

  /* 16 */ var canAccessCMSFDashboard = userpermission.includes("Dashboard-management-Read")
  /* 17 */ var canAccessPolicyDashboard = userpermission.includes("Dashboard-policy-Read")
  /* 18 */ var canAccessInvestorDashboard = userpermission.includes("Dashboard-investor-Read")
  /* 19 */ var canAccessApproveInwardDividend = userpermission.includes("Inward-Dividend-list-sub-investorsDetails-sub-approve")
  /* 20 */ var canGiveScrutinyApproval = userpermission.includes("Claim-Scrutiny-Approval")
  /* 21 */ var canGiveOMCApproval = userpermission.includes("Claim-OMC-Approval")
  /* 22 */ var canGiveBOGApproval = userpermission.includes("Claim-BOG-Approval")
  /* 23 */ var canGiveSettledApproval = userpermission.includes("Claim-Settled-Approval")
  /* 24 */ var canAccessHRLeaveDashboard = userpermission.includes("leave-HR-dashboard")
  /* 25 */ var canAccessN1LeaveDashboard = userpermission.includes("leave-N1-dashboard")

  return (
    <div className={classes.root}>
      <>
        <Header history={props.history} />
        <Sidebar />
        {/* <DynamicSidebar history={props.history}/> */}
        <div 
          className={classnames(classes.content, {
            [classes.contentShift]: layoutState.isSidebarOpened,
          })}
        >
        <div className={classes.fakeToolbar}  style={{padding:"50px 50px"}}/>
          {/* <TheContent/> */}
          <div style={{marginBottom:"100px"}}>
          <Switch >
            <Route exact path="/app/dashboard" component={DashboardGeneral} />
            {canAccessPolicyDashboard && <Route exact path="/app/dashboard/policy-level" component={Dashboard}/>}
            {canAccessCMSFDashboard && <Route exact path="/app/dashboard/cmsf-management" component={DashboardManagement} />}
            {canAccessInvestorDashboard && <Route exact path="/app/dashboard/investors" component={DashboardInvestors} />}

            {/* turn on this when search dividend is allowed {canAccessClaimMyDividend &&<Route path="/app/search-dividend/claim/:id" component={SearchToClaimComponent} />} */}
            {/* the below line is when  search dividend is stopped */}
            {/* {canAccessClaimMyDividend &&<Route path="/app/search-dividend/claim/:id" component={SearchToClaimComponent} />}
            {canAccessClaimMyDividend && <Route path="/app/search-dividend" component={SearchDividendComponent} />} */}
            
            {canAccessClaimMyDividend && <Route path="/app/search-dividend" component={SearchToClaimComponent} />}
            
            <Route path="/app/lodgeClaim" component={LodgeClaimComponent} />
            <Route path="/app/lodged-claim/list" component={ClaimList} />
            <Route path="/app/lodged-claim/edit/:id" component={EditLodgedClaimComponent} />
            <Route path="/app/lodged-claim/view/:id" component={ViewLodgedClaimComponent} />

            {(userType != 'Investor')&& <Route path="/app/reportOfInvestorSearch" component={ReportOfInvestorSearch} />}
            {/* {(userType != 'Investor')&& <Route path="/app/reportOfInvestorSearch/view" component={UiOfReport} />} */}

            {(userType != 'Investor')&& <Route path="/app/companyStatusReport" component={CompanyStatus}/>}
            {(userType != 'Investor')&& <Route path="/app/companySummaryReport" component={CompanySummary}/>}
            {(userType != 'Investor')&& <Route path="/app/LedgerWiseInvestorReport" component={ReportOfLedger}/>}
            {(userType != 'Investor')&& <Route path="/app/IssuerWiseInvestorReport" component={IssuerWiseInvestorR}/>}
            {(userType != 'Investor')&& <Route path="/app/dateWiseIssuerReport" component={dateWiseIssuerReport}/>}
            

            {(userType === 'Super_Admin'|| userType === 'Admin_CMSF') && <Route exact path="/app/employee-attendace" component={Attendance} />}

            {(userType === 'Super_Admin'|| userType === 'Admin_CMSF') && <Route exact path="/app/leave-application" component={LeaveApplication} />}

            {canAccessN1LeaveDashboard && (userType === 'Super_Admin'|| userType === 'Admin_CMSF') && <Route exact path="/app/leave-approval" component={LeaveApprovalDashboard} />}
            {(canAccessHRLeaveDashboard && (userType === 'Super_Admin'|| userType === 'Admin_CMSF')) && <Route exact path="/app/hr-leave-approval" component={HrLeaveApprovalDashboard} />}

            {canAccessHRLeaveDashboard && (userType === 'Super_Admin'|| userType === 'Admin_CMSF') && <Route exact path="/app/holiday-declare" component={HolidayDeclare} />}

            {canAccessClaimListCMSF && <Route path="/app/claim-settlement/claim/cmsf/list" component={ClaimListCmsf} />}
            {canAccessClaimListCMSF &&<Route path="/app/claim-settlement/claim/cmsf/view/:id" component={ClaimViewCmsf} />}
            {canAccessClaimListIssuer &&<Route path="/app/claim-settlement/claim/issuer/list" component={ClaimListIssuer} />}
            {canAccessClaimListIssuer && <Route path="/app/claim-settlement/claim/issuer/view/:id" component={ClaimViewIssuer} />}
            {canAccessClaimListInvestor && <Route path="/app/claim-settlement/claim/investors/list" component={ClaimListInvestors} />}
            {canAccessClaimListInvestor && <Route path="/app/claim-settlement/claim/investors/view/:id" component={ClaimViewInvestors} />}

        <Route path="/app/claim-settlement/claim/report/:id" component={ClaimReport} />
            
            <Route path="/app/trackClaim" component={TrackClaim} />
            <Route
              exact
              path="/app/inward-dividend"
              render={() => <Redirect to="/app/inward-dividend/list" />} />
            {canAccessInwardAdd && <Route exact path="/app/inward-dividend/new-add" component={AddInwordDividendComponent}/>}
            {canAccessInwardList && <Route exact path="/app/inward-dividend/list" component={ListInwardDividendComponent} />}
            {canAccessInwardList && <Route exact path="/app/inward-dividend/views/:id" component={ViewInwordDividendComponent} />} 
            {canAccessInwardList && canAccessInwardDivWiseEdit && <Route exact path="/app/inward-dividend/edit/:id" component={EditInwordDividendComponent} />} 
            {canAccessInwardList && canAccessInwardDivWiseInvestorList && <Route exact path="/app/inward-dividend/investor-informations/:id" component={ListInvestorsDetailsComponent} />}   {/*for investor list  page with overall approve button ALSO Row-wise aaprove*/}
            {canAccessInwardList && canAccessInwardDivWiseInvestorList && <Route exact path="/app/inward-dividend/investor-informations/investor/view/:id" component={ViewInvestor} />}      {/*for individual investor details page with single approve button*/}

            {canAccessInvestorInfo && <Route exact path="/app/investor-informations" component={ListInvestorsInformationComponent} />}
            {canAccessInvestorInfo && <Route exact path="/app/investor-informations/views/:id" component={ViewInvestorInfo} />}
            {canAccessInvestorInfo && <Route exact path="/app/investor-informations/edit/:id" component={ViewInvestorInfo} />}

            {canAccessIssuerAdd  && <Route exact path="/app/issuer/new-add" component={AddIssuer} />}
            {canAccessIssuerList && <Route exact path="/app/issuer/list" component={ListOfIssuers} />}
            {<Route exact path="/app/issuer/yearDefine" component={DefineDivYearForm} />}
            {canAccessIssuerList && <Route exact path="/app/issuer/list/:id" component={IssuerDetail} />}
            {canAccessIssuerList && canEditIssuerInfo &&  <Route exact path="/app/issuer/edit/:id" component={Edit} />}

            <Route exact path="/app/password-change" component={ChangePassword} />
            <Route exact path="/app/profile" component={Profile} />
            <Route exact path="/app/profile/update" component={ProfileEdit} />

           {(userType === 'Super_Admin') && <Route exact path="/app/role" component={Role} />}
           {(userType === 'Super_Admin' || userType === 'Admin_CMSF') && <Route exact path="/app/CreateMenu" component={CreateMenu} />}
           {(userType === 'Super_Admin') && <Route exact path="/app/permissions" component={Permission} />}
           {(userType === 'Super_Admin') && <Route exact path="/app/permission-types" component={PermissionTypes} />}

           {(userType === 'Super_Admin'|| userType === 'Admin_CMSF') && <Route exact path="/app/add-receive-account/bank" component={BankToReceiveDividend} />}
           {(userType === 'Super_Admin'|| userType === 'Admin_CMSF') && <Route exact path="/app/add-receive-account/bo" component={BODetailsToReceiveDividend} />}

           {(userType === 'Super_Admin'|| userType === 'Admin_CMSF') && <Route exact path="/app/ledger/create" component={CreateLedger} />}
           {(userType === 'Super_Admin'|| userType === 'Admin_CMSF') && <Route exact path="/app/voucher/create" component={CreateVoucher} />}
           {(userType === 'Super_Admin'|| userType === 'Admin_CMSF') && <Route exact path="/app/declare/LoanFDR" component={LoanFDRDeclaration} />}
           

            {/* <Route exact path="/app/user-role" component={Users} /> */}
            {(userType === 'Super_Admin') && <Route exact path="/app/user-permission" component={UserPermission} />}

            <Route exact path="/app/bank-informations" component={BankInformations} />
            <Route exact path="/app/view/bank-informations" component={ViewBankInfo} />
            {/* <Route exact path="/app/add/bank-informations" component={UpdateBankInformation} /> */}
            {/* <Route exact path="/app/view/bank-informations" component={BankInformations} /> */}

           { (userType === 'Super_Admin') && <Route exact path="/app/user-investor" component={FilterTableComponent} />}
           { (userType === 'Super_Admin') && <Route exact path="/app/user-issuer" component={ListIssuerType} />}
           { (userType === 'Super_Admin') && <Route exact path="/app/user-issuer-create" component={IssuerUserCreate} />}
           { (userType === 'Super_Admin') && <Route exact path="/app/user-cmsf" component={ListCmsfType} />}
           { (userType === 'Super_Admin') &&<Route exact path="/app/user-cmsf-create" component={CmsfUserCreate} />}

           { (userType === 'Super_Admin' || userType === 'Admin_CMSF') &&<Route exact path="/app/settlement/dividend-settlement" component={DividendSettlement} />}
           { (userType === 'Super_Admin' || userType === 'Admin_CMSF') &&<Route exact path="/app/settlement/ready-to-settled-dividends" component={ReadyToSettled} />}
           { (userType === 'Super_Admin' || userType === 'Admin_CMSF') &&<Route exact path="/app/settlement/settled-dividends" component={SettledDividend} />}
           { (userType === 'Super_Admin' || userType === 'Admin_CMSF') &&<Route exact path="/app/settlement/data-uploaded-by-employee" component={DataUploadedByOfficers} />}
           

           { (userType === 'Super_Admin' || userType === 'Admin_CMSF') &&<Route exact path="/app/systemmaintainance/undefineconverttodefine" component={UndefineConverttoDefine} />}
           { (userType === 'Super_Admin' || userType === 'Admin_CMSF') &&<Route exact path="/app/systemmaintainance/issuerWiseDataEntry" component={issuerWiseDataEntry} />}
           { (userType === 'Super_Admin' || userType === 'Admin_CMSF') &&<Route exact path="/app/systemmaintainance/undefineamountadd" component={IssuerWiseUndefineAmountIncrease} />}
           
            <Route  path="/*"  component={pageofUauth} />

          </Switch>
          </div>
          <Box style={{
            // position:"absolute",paddingBottom:"0px",paddingTop:"100px"
             //display: "bottom",flexDirection: "row",
            // height: "100%",
            // marginTop: "400px",
            // maxWidth:"100vw",
            // overflowX:"auto",
            // justifyContent:"space-around",position:"fixed",bottom: 0,backgroundColor:"white",width:"100vw" ,boxShadow:"2px 2px 2px 2px gray",border:"5px solid red"
            position: "fixed",
            bottom: 0,
            left: layoutState.isSidebarOpened ? "250px" : "90px", // Adjust based on sidebar width
            width: layoutState.isSidebarOpened ? "calc(100% - 250px)" : "calc(100% - 90px)", // Makes sure it stays out of the sidebar
            backgroundColor: "white",
            boxShadow: "2px 2px 2px 2px gray",
            display: "flex",
            flexWrap: "wrap",
            justifyContent: "space-around", // Space out elements
            alignItems: "center",
            padding: "10px",
            overflow: "hidden",
          }}
            mt={5}
            // width={"100%"}
            display={"flex"}
            alignItems={"center"}
          >
            <div>
              <Link
                color={'primary'}
                href={'https://cmsfbd.org/blog-details/about-cmsf'}
                target={'_blank'}
                className={classes.link}
                style={{margin: '0 10px'}}
              >
                About Us
              </Link>
              <Link
                color={'primary'}
                href={'https://cmsfbd.org/contact-us'}
                target={'_blank'}
                className={classes.link}
                style={{margin: '0 10px'}}
              >
                Contact
              </Link>
            </div>
            <div>
              <Link
                href={'https://www.facebook.com/cmsfbd'}
                target={'_blank'}
                style={{margin: '0 10px'}}
              >
                <IconButton aria-label="facebook">
                  <Icon
                    path={FacebookIcon}
                    size={1.5}
                    color="#1877F2" 
                    />
                </IconButton>
              </Link>
              <Link
                href={'https://www.linkedin.com/company/cmsfbd/'}
                target={'_blank'}
                style={{margin: '0 10px'}}
              >
                <IconButton aria-label="linkedin">
                  <Icon
                    path={mdiLinkedin}
                    size={1.5}
                    color="#0072B1" 
                    />
                </IconButton>
              </Link>
             
            </div>
            <img src={CmsfLogo} alt="Logo 5" style={{ margin: '0 10px', height: '40px', width: '40px' }} />
            <img src={MofLogo} alt="Logo 1" style={{ margin: '0 10px' , height: '40px', width: '40px'}} />
            <img src={BsecLogo} alt="Logo 2" style={{ margin: '0 10px', height: '40px', width: '40px'}} />
            <img src={BbLogo} alt="Logo 3" style={{ margin: '0 10px' ,  height: '40px', width: '40px'}} />
            <img src={DseLogo} alt="Logo 4" style={{ margin: '0 10px' , height: '40px', width: '40px'}} />
            <img src={CseLogo} alt="Logo 5" style={{ margin: '0 10px',  height: '40px', width: '40px' }} />
            <img src={BaplcLogo} alt="Logo 6" style={{ margin: '0 10px',height: '40px', width: '40px' }} />
            <img src={CdblLogo} alt="Logo 7" style={{ margin: '0 10px' ,height: '40px', width: '40px'}} />
            <img src={CcblLogo} alt="Logo 8" style={{ margin: '0 10px', height: '40px', width: '40px' }} />
            <img src={BicmLogo} alt="Logo 9" style={{ margin: '0 10px' ,height: '40px', width: '40px'}} />
            <img src={NbrLogo} alt="Logo 10" style={{ margin: '0 10px' ,height: '40px', width: '40px'}} />
            {/* <Footer /> */}
          </Box>
        </div>
        
      </>
    </div>
  );
  // function privateRoute (children) {
  //   if (userType === 'Issuer')
  //   return (
  //     {children}
  //   )
  //   }
};



export default withRouter(Layout);