import {
  Button,
  CircularProgress,
  FormControl,
  Grid,
  MenuItem,
  TextField,
} from "@material-ui/core";
import { Autocomplete } from "@mui/material";
import React, { useEffect, useState } from "react";
import Swal from "sweetalert2";
import axios from "../../../utils/axios";
import PageTitle from "../../../components/PageTitle/PageTitle";

const IssuerWiseDataEntry = () => {
  const [formValues, setFormValues] = useState({
    issuerName: "",
    dividendType: "Stock",
    transferType: "",
    quantity: "",
    rate: "",
    date: "",
  });

  const [issuerList, setIssuerList] = useState([]);
  const [transferTypeOptions, setTransferTypeOptions] = useState([
    "Transfer In",
    "Transfer Debit",
    "Transfer Ownership",
  ]);
  const [errors, setErrors] = useState({});
  const [loading, setLoading] = useState(false);

  // Fetch issuer names from API
  useEffect(() => {
    const fetchIssuerNames = async () => {
      setLoading(true);
      try {
        const { data } = await axios.get("/issuer/get-info");
        if (data && data.payload) {
          const formattedIssuerList = data.payload.map((issuer) => ({
            id: issuer.id,
            displayName: `${issuer.name} (${issuer.tradecode})`,
          }));
          setIssuerList(formattedIssuerList);
        }
      } catch (error) {
        console.error("Error fetching issuer names:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchIssuerNames();
  }, []);

  // Handle change in dividend type
  useEffect(() => {
    if (formValues.dividendType === "Stock") {
      setTransferTypeOptions([
        "TRANSFER IN",
        "TRANSFER_DEBIT",
        "TRANSFER_CH_OWNERSHIP I",
      ]);
    } else if (formValues.dividendType === "Cash") {
      setTransferTypeOptions(["Debit", "Credit"]);
    }
    setFormValues((prev) => ({ ...prev, transferType: "" }));
  }, [formValues.dividendType]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormValues((prev) => ({ ...prev, [name]: value }));
    setErrors((prev) => ({ ...prev, [name]: "" }));
  };

  const handleIssuerChange = (event, value) => {
    setFormValues((prev) => ({ ...prev, issuerName: value }));
    setErrors((prev) => ({ ...prev, issuerName: "" }));
  };

  const validateForm = () => {
    const validationErrors = {};
    if (!formValues.issuerName)
      validationErrors.issuerName = "Issuer Name is required.";
    if (!formValues.dividendType)
      validationErrors.dividendType = "Dividend Type is required.";
    if (!formValues.transferType)
      validationErrors.transferType = "Transfer Type is required.";
    if (!formValues.quantity || isNaN(formValues.quantity))
      validationErrors.quantity = "Valid Quantity is required.";
    if (!formValues.rate || isNaN(formValues.rate))
      validationErrors.rate = "Valid Rate is required.";
    if (!formValues.date)
      validationErrors.date = "Date is required.";

    setErrors(validationErrors);
    return Object.keys(validationErrors).length === 0;
  };

  const handleSubmit = async () => {
    if (!validateForm()) return;

    const selectedIssuer = issuerList.find(
      (issuer) => issuer.displayName === formValues.issuerName
    );
    const issueruid = selectedIssuer ? selectedIssuer.id : "";

    // Convert dividendType to numbers
    const dividendTypeValue = formValues.dividendType === "Stock" ? 1 : 0;

    const payload = {
      issueruid,
      dividendtype: dividendTypeValue,
      transfertype: formValues.transferType,
      quantity: parseFloat(formValues.quantity),
      rate: parseFloat(formValues.rate),
      entryDate: formValues.date, 
    };

    try {
      setLoading(true);
      const { data } = await axios.post("/investor-informations/issuer_wise_cash_stock_entry", payload);
      Swal.fire({
        title: "Success",
        text: data.payload.message || "Data submitted successfully!",
        icon: "success",
        confirmButtonText: "OK",
      });
    } catch (error) {
      console.error("Error submitting data:", error);
      Swal.fire({
        title: "Error",
        text: "Failed to submit data. Please try again.",
        icon: "error",
        confirmButtonText: "OK",
      });
    } 
    finally {
      setLoading(false);
    }
  };

  return (
    <>
      <PageTitle title="Issuer wise Cash / Stock Entry" />
      <Grid container spacing={3} style={{ marginTop: "20px" }}>
        {/* Input Row */}
        <Grid item container spacing={3} lg={12} xs={12}>
          {/* Issuer Name */}
          <Grid item lg={6} md={6} sm={12} xs={12}>
            <FormControl fullWidth>
              <Autocomplete
                options={issuerList.map((issuer) => issuer.displayName)}
                value={formValues.issuerName || ""}
                onChange={handleIssuerChange}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Issuer Name"
                    variant="outlined"
                    error={!!errors.issuerName}
                    helperText={errors.issuerName || ""}
                  />
                )}
              />
            </FormControl>
          </Grid>

          {/* Dividend Type */}
          <Grid item lg={6} md={6} sm={12} xs={12}>
            <FormControl fullWidth>
              <TextField
                select
                label="Dividend Type"
                name="dividendType"
                value={formValues.dividendType}
                onChange={handleChange}
                error={!!errors.dividendType}
                helperText={errors.dividendType}
              >
                <MenuItem value="Stock">Stock</MenuItem>
                <MenuItem value="Cash">Cash</MenuItem>
              </TextField>
            </FormControl>
          </Grid>

          {/* Transfer Type */}
          <Grid item lg={6} md={6} sm={12} xs={12}>
            <FormControl fullWidth>
              <TextField
                select
                label="Transfer Type"
                name="transferType"
                value={formValues.transferType}
                onChange={handleChange}
                error={!!errors.transferType}
                helperText={errors.transferType}
              >
                {transferTypeOptions.map((option) => (
                  <MenuItem key={option} value={option}>
                    {option}
                  </MenuItem>
                ))}
              </TextField>
            </FormControl>
          </Grid>

          {/* Quantity */}
          <Grid item lg={6} md={6} sm={12} xs={12}>
            <TextField
              label="Quantity"
              type="number"
              name="quantity"
              value={formValues.quantity}
              onChange={handleChange}
              fullWidth
              InputLabelProps={{ shrink: true }}
              error={!!errors.quantity}
              helperText={errors.quantity}
            />
          </Grid>

          {/* Rate */}
          <Grid item lg={6} md={6} sm={12} xs={12}>
            <TextField
              label="Rate"
              type="number"
              step="0.01" // Allows for float values
              name="rate"
              value={formValues.rate}
              onChange={handleChange}
              fullWidth
              InputLabelProps={{ shrink: true }}
              error={!!errors.rate}
              helperText={errors.rate}
            />
          </Grid>

          {/* Date */}
          <Grid item lg={6} md={6} sm={12} xs={12}>
            <TextField
              label="Date"
              type="date"
              name="date"
              value={formValues.date}
              onChange={handleChange}
              fullWidth
              InputLabelProps={{ shrink: true }}
              error={!!errors.date}
              helperText={errors.date}
            />
          </Grid>
        </Grid>

        {/* Submit Button Row */}
        <Grid
          item
          lg={12}
          md={12}
          sm={12}
          xs={12}
          style={{ textAlign: "center" }}
        >
          <Button
            variant="contained"
            color="primary"
            size="large"
            onClick={handleSubmit}
            style={{ marginTop: "20px" }}
            disabled={loading}
          >
            {loading ? (
              <CircularProgress size={24} style={{ color: "white" }} />
            ) : (
              "Save"
            )}
          </Button>
        </Grid>
      </Grid>
    </>
  );
};

export default IssuerWiseDataEntry;
