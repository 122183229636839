import { Button, CircularProgress, FormControl, Grid, MenuItem, TextField } from "@material-ui/core";
import { Autocomplete } from "@mui/material";
import jsPDF from "jspdf";
import "jspdf-autotable";
import { MaterialReactTable } from "material-react-table";
import React, { useEffect, useState } from "react";
import Swal from "sweetalert2";
import PageTitle from "../../../components/PageTitle/PageTitle";
import axios from "../../../utils/axios";

const DateWiseIssuerReport = () => {

  const [formValues, setFormValues] = useState({
    issuerName: "",
    dividendType: "",
    fromDate: "",
    toDate: "",
  });

  const [issuerList, setIssuerList] = useState([]);
  const [errors, setErrors] = useState({});
  const [loading, setLoading] = useState(false);
  const [tableData, setTableData] = useState([]);

  // Fetch issuer names from API
  useEffect(() => {
    const fetchIssuerNames = async () => {
      setLoading(true);
      try {
        const { data } = await axios.get("/issuer/get-info");
        if (data?.payload) {
          const formattedIssuerList = data.payload.map((issuer) => ({
            id: issuer.id,
            displayName: `${issuer.name} (${issuer.tradecode})`,
          }));
          setIssuerList([{ id: "All", displayName: "All (All Companies)" }, ...formattedIssuerList]);
        }
      } catch (error) {
        console.error("Error fetching issuer names:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchIssuerNames();
  }, []);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormValues((prev) => ({ ...prev, [name]: value }));
    setErrors((prev) => ({ ...prev, [name]: "" }));
  };

  const handleIssuerChange = (event, value) => {
    setFormValues((prev) => ({ ...prev, issuerName: value }));
    setErrors((prev) => ({ ...prev, issuerName: "" }));
  };

  const validateForm = () => {
    const validationErrors = {};
    if (!formValues.issuerName) validationErrors.issuerName = "Issuer Name is required.";
    if (!formValues.dividendType) validationErrors.dividendType = "Dividend Type is required.";
    if (formValues.fromDate && !formValues.toDate) {
      validationErrors.toDate = "To Date must be selected.";
    } else if (!formValues.fromDate && formValues.toDate) {
      validationErrors.fromDate = "From Date must be selected.";
    }

    setErrors(validationErrors);
    return Object.keys(validationErrors).length === 0;
  };

  const handleSubmit = async () => {
    if (!validateForm()) return;

    const selectedIssuer = issuerList.find(
      (issuer) => issuer.displayName === formValues.issuerName
    );
    const issuerid = selectedIssuer ? selectedIssuer.id : "";

    const payload = {
      issuerid,
      isStock: formValues.dividendType === "Cash" ? 0 : 1,
      startDate: formValues.fromDate || "All",
      endDate: formValues.toDate || "All",
    };

    try {
      const { data } = await axios.post(
        "/investor-informations/get_issuer_wise_cash_stock_entry",
        payload
      );
    
      if (!data.payload || data.payload.length === 0) {
        Swal.fire({
          title: "No Data Found",
          text: "The report contains no data. Please try again with different inputs.",
          icon: "info",
          confirmButtonText: "OK",
        });
        setTableData([]); // Optionally clear the table if no data is found
      } 
      else 
      {
        setTableData(data.payload);
        Swal.fire({
          title: "Success",
          text: data.message || "Report generated successfully!",
          icon: "success",
          confirmButtonText: "OK",
          timer: 1000,
          showConfirmButton: true,
        });
      }
    } 
    catch (error) {
      console.error("Error submitting report:", error);
      Swal.fire({
        title: "Error",
        text: error?.response?.data?.message || "Failed to generate report. Please try again.",
        icon: "error",
        confirmButtonText: "OK",
      });
    }
    
  };

  const columns = [
    {
      accessorKey: 'id',
      header: 'SL',
      size: 50,
      Cell: ({ row }) => row.index + 1,
     },
    // { accessorKey: "id", header: "ID", size: 50 },
    { accessorKey: "issuer_name", header: "Issuer Name", size: 50 },
    {
      accessorKey: "transaction_date",
      header: "Date",
      size: 50,
      Cell: ({ cell }) => {
        const date = new Date(cell.getValue());
        return date.toLocaleDateString("en-GB", {
          day: "2-digit",
          month: "short",
          year: "numeric",
        }).replace(/ /g, "-");
      },
    },
    { accessorKey: "transaction_type", header: "Type", size: 50  },
    { accessorKey: "dbcr", header: "DbCr", size: 50 },
    { accessorKey: "boid", header: "BoId", size: 50 },
    { accessorKey: "quantity", header: "QTY", size: 50 }
    // { accessorKey: "rate", header: "Rate", size: 50 },
    // { accessorKey: "in_values", header: "InValues", size: 50 },
    // { accessorKey: "out_values", header: "OutValues", size: 50 },
  ];
  const calculateTotals = (tableData) => {
    const filteredDataCr = tableData.filter(row => row.dbcr === "CR");
    const filteredDataDr = tableData.filter(row => row.dbcr === "DR");
   
    const totalCr = filteredDataCr
      .reduce((sum, row) => sum + (parseFloat(row.quantity) || 0), 0)
      .toFixed(3);
  
    const totalDr = filteredDataDr
      .reduce((sum, row) => sum + (parseFloat(row.quantity) || 0), 0)
      .toFixed(3);
  
    const totalBalance = (parseFloat(totalCr) - parseFloat(totalDr)).toFixed(3);
  
    return {
      totalCr,
      totalDr,
      totalBalance,
    };
  };
  
  const handleDownloadPDF = () => {
    const doc = new jsPDF("portrait"); // Portrait orientation
    const { totalCr, totalDr, totalBalance } = calculateTotals(tableData);
    console.log(totalCr);

    const pageWidth = doc.internal.pageSize.getWidth();
    const pageHeight = doc.internal.pageSize.getHeight();
  
    const companyInfo = {
      name: "Capital Market Stabilization Fund (CMSF)",
      address:
        "3rd Floor, DCCI Building, 65-66 Motijheel C/A Dhaka-1000, Bangladesh.\nHotline: +8802-41052430-31, Mobile : +8801743059940,\nE-mail : claim@cmsfbd.org, Web : www.cmsfbd.org/",
    };
  
    const reportInfo = {
      title: "Issuer WISE IN/OUT VALUES",
      instrument: `Issuer: ${formValues.issuerName}`,
      dateRange: `From ${formValues.fromDate || "All"} To ${formValues.toDate || "All"}`,
    };
  
    // Add company header
    doc.setFontSize(12);
    doc.text(companyInfo.name, 14, 13);
    doc.setFontSize(8);
    doc.text(companyInfo.address, 14, 19);
    doc.setFont("helvetica", "bold");
  
    // Add report title and other info
    doc.setFontSize(9);
    doc.text(reportInfo.title, pageWidth - 14, 15, { align: "right" });
  
    doc.text(reportInfo.instrument, 14, 36);
    doc.text(reportInfo.dateRange, 14, 40);
  
    // Table columns
    const tableColumns = columns.map((column) => column.header);
  
    // Prepare table data for PDF
    const tableDataForPDF = tableData.map((row, index) => {
      return columns.map((column) => {
        if (column.accessorKey === "id") {
          return index + 1;
        }
        if (column.accessorKey === "transaction_date") {
          const rawDate = row[column.accessorKey];
          const date = new Date(rawDate);
          return date
            .toLocaleDateString("en-GB", {
              day: "2-digit",
              month: "short",
              year: "numeric",
            })
            .replace(/ /g, "-"); // Format: '02-Jan-2022'
        }
        return row[column.accessorKey];
      });
    });

  
    // Get current date & time
    const currentDateTime = new Date()
      .toLocaleString("en-GB", {
        day: "2-digit",
        month: "short",
        year: "numeric",
        hour: "2-digit",
        minute: "2-digit",
        second: "2-digit",
        hour12: false,
      })
      .replace(",", "");
  
    doc.autoTable({
      head: [tableColumns],
      body: tableDataForPDF,
      startY: 43,
      theme: "grid",
      styles: {
        fontSize: 7,
        cellPadding: 1.2,
        lineWidth: 0,
      },
      headStyles: {
        fillColor: [255, 255, 255],
        textColor: "#000000",
        halign: "center",
        lineWidth: 0.5,
      },
      bodyStyles: {
        textColor: "#000000",
        halign: "left",
      },
      columnStyles: {
        0: { halign: "left" },
      },
      tableWidth: "auto",

        // Bold "Total" and total quantity
    
      didDrawCell: (data) => {
        if (data.section === "body") {
          const doc = data.doc;
          const { cell } = data;
  
          doc.setLineDash([1, 1], 0);
          doc.setDrawColor(130, 130, 130);
  
          doc.line(cell.x, cell.y, cell.x + cell.width, cell.y); // Top line
          doc.line(cell.x, cell.y + cell.height, cell.x + cell.width, cell.y + cell.height); // Bottom line
        }
      },
      didDrawPage: (data) => {
        // Add Date & Time on the bottom-left corner
        doc.setFont("helvetica", "italic");
        doc.setFontSize(8);
        doc.setTextColor(149, 149, 149);
        doc.text(`Print Date & Time: ${currentDateTime}`, 14, pageHeight - 10);
  
        // Add Page Number on the bottom-right corner
        const pageNumber = `Page ${data.pageNumber}`;
        doc.text(pageNumber, pageWidth - 14, pageHeight - 10, { align: "right" });
      },
    });

// Get the last table Y position
const finalY = doc.lastAutoTable.finalY + 5; // Add some space below the table

// Add total row manually, each in a new line
doc.setFont("helvetica", "bold");
doc.setFontSize(10);

doc.text(`Total CR (Received ${formValues.dividendType}): ${totalCr}`, 14, finalY); // First line
doc.text(`Total DR (Settled ${formValues.dividendType}): ${totalDr}`, 14, finalY + 5); // Second line
doc.text(`Total Balance: ${totalBalance}`, 14, finalY + 10); // Third line

  
    // Save the PDF
    doc.save("Issuer_Wise_IN_OUT_Values_Report.pdf");
  };
  

  return (
    <>
      <PageTitle title="Date-wise Dividend Outstanding Report" />

      <Grid container spacing={3} style={{ marginTop: "20px" }}>
        <Grid item container spacing={3} lg={12} xs={12}>
          <Grid item lg={3} md={6} sm={12} xs={12}>
            <FormControl fullWidth>
              <Autocomplete
                options={issuerList.map((issuer) => issuer.displayName)}
                value={formValues.issuerName || ""}
                onChange={handleIssuerChange}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Issuer Name"
                    variant="outlined"
                    error={!!errors.issuerName}
                    helperText={errors.issuerName || ""}
                    InputProps={{
                      ...params.InputProps,
                      endAdornment: (
                        <>
                          {loading ? (
                            <CircularProgress color="inherit" size={24} />
                          ) : null}
                          {params.InputProps.endAdornment}
                        </>
                      ),
                    }}
                  />
                )}
              />
            </FormControl>
          </Grid>

          <Grid item lg={3} md={6} sm={12} xs={12}>
            <FormControl fullWidth>
              <TextField
                select
                label="Dividend Type"
                name="dividendType"
                value={formValues.dividendType}
                onChange={handleChange}
                error={!!errors.dividendType}
                helperText={errors.dividendType}
              >
                <MenuItem value="Cash">Cash</MenuItem>
                <MenuItem value="Stock">Stock</MenuItem>
              </TextField>
            </FormControl>
          </Grid>

          <Grid item lg={3} md={6} sm={12} xs={12}>
            <TextField
              label="From Date"
              type="date"
              name="fromDate"
              value={formValues.fromDate}
              onChange={handleChange}
              fullWidth
              InputLabelProps={{ shrink: true }}
              error={!!errors.fromDate}
              helperText={errors.fromDate}
            />
          </Grid>

          <Grid item lg={3} md={6} sm={12} xs={12}>
            <TextField
              label="To Date"
              type="date"
              name="toDate"
              value={formValues.toDate}
              onChange={handleChange}
              fullWidth
              InputLabelProps={{ shrink: true }}
              error={!!errors.toDate}
              helperText={errors.toDate}
            />
          </Grid>
        </Grid>

        <Grid item lg={12} md={12} sm={12} xs={12} style={{ textAlign: "center" }}>
          <Button
            variant="contained"
            color="primary"
            size="large"
            onClick={handleSubmit}
            style={{ marginTop: "15px" }}
            disabled={loading}
          >
            {loading ? <CircularProgress size={24} style={{ color: "white" }} /> : "Submit"}
          </Button>
        </Grid>

        <Grid item lg={12} md={12} sm={12} xs={12} style={{ textAlign: "center"}}>
          <Button
            variant="contained"
            color="secondary"
            size="large"
            onClick={handleDownloadPDF}
            style={{ marginTop: "2px" , marginBottom: "15px"}}
            disabled={loading || tableData.length === 0}
          >
            Download PDF
          </Button>
        </Grid>
      </Grid>

      {tableData.length > 0 && (
       <MaterialReactTable
       columns={columns}
       data={tableData}
       enablePagination
       enableSorting
       enableColumnFilters
       initialState={{ density: "compact" }}
       muiTableContainerProps={{sx:{maxHeight:"50vh"}}}
       muiTableBodyCellProps={{ sx: { border: "0.5px solid #e0e0e0" } }}
       muiTableHeadCellProps={{ sx: { border: "0.5px solid #e0e0e0" } }}
       muiTableFooterRowProps={{
         sx: { fontWeight: "bold", backgroundColor: "#f1f1f1" },
       }}
       renderBottomToolbarCustomActions={() => {
         const { totalCr, totalDr, totalBalance } = calculateTotals(tableData);

         return (
          <div style={{  fontSize: "15px",padding: "10px", fontWeight: "bold" }}>
          <div>Total CR (Received {formValues.dividendType}): {totalCr}</div>
          <div>Total DR (Settled {formValues.dividendType}): {totalDr}</div>
          <div>Total Balance: {totalBalance}</div>
        </div>
   
         );
       }}
     />
     
      )}
    </>
  );
};

export default DateWiseIssuerReport;
