import { Button, Grid } from '@material-ui/core';
import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import Swal from 'sweetalert2';
import axios from "../../../../utils/axios";

export default function DataTable(){
  const userpermission = localStorage.getItem("permission")
    /* 19 */ var canAccessApproveInwardDividend = userpermission.includes("Inward-Dividend-list-sub-investorsDetails-sub-approve")

  const { t } = useTranslation();
    const [isLoading, setIsLoding] =useState(true)
    const [isSuccess, setIsSuccess] =useState(false)
    const [data, setData] = useState({})
    const [stateOfDividend, setStateOfDividend] = useState({})

    const params = useParams();
    useEffect(() => {
        axios.get(`investor-informations/${params.id}`)
        .then((res) =>{
            if(res?.data?.success){
                setIsSuccess(true)
                setIsLoding(false)
                setData(res?.data?.payload)
                setStateOfDividend(res?.data?.payload.status)
            }
        })
        .catch((err) =>{
            alert("There was an error occured!")
        })
      }, [params.id,stateOfDividend]);

console.log(data);
console.log(stateOfDividend);

      // const approveHandler = (agree) => {
        
      //       if (agree) {
      //         Swal.fire({
      //           title: 'Are you sure?',
      //           text: 'You are about to approve the investor information.',
      //           icon: 'warning',
      //           showCancelButton: true,
      //           confirmButtonText: 'Yes, approve it!',
      //           cancelButtonText: 'No, cancel!',
      //         }).then((result) => {
      //           if (result.isConfirmed) {
      //             axios
      //               .put(`investor-informations/${params.id}`, { status: 1 })
      //               .then((res) => {
      //                 if (res.data.success) {
      //                   setStateOfDividend(res?.data?.payload.status)
      //                   setIsSuccess(true);
      //                   setIsLoding(false);
      //                   setData({ ...data, status: 1 });
      //                 }
      //               })
      //               .catch((err) => {
      //                 alert('There was an error occured!');
      //               });
      //           } else if (result.dismiss === Swal.DismissReason.cancel) {
      //             Swal.fire('Cancelled', 'The investor information was not approved.', 'error');
      //           }
      //         });
      //       } else {
      //         alert('You must agree before approving.');
      //       }
        
      // };
  
      const declineHandler = (agree) =>{
        if (agree) {
          Swal.fire({
            title: 'Are you sure to DECLINE the investor information?',
           // text: 'You are about to DECLINE the investor information.',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Yes, DECLINE it!',
            cancelButtonText: 'No, cancel!',
          }).then((result) => {
            if (result.isConfirmed) {
              axios
                .put(`investor-informations/update-status/${params.id}`, { status: 4 })
                .then((res) => {
                  if (res.data.success) {
                    setStateOfDividend(res?.data?.payload.status)
                    setIsSuccess(true);
                    setIsLoding(false);
                    //setData({ ...data, status: 1 });
                  }
                })
                .catch((err) => {
                  alert('There was an error occured!');
                });
            } else if (result.dismiss === Swal.DismissReason.cancel) {
              //Swal.fire('The investor information was not approved.');
            }
          });
        } else {
          alert('Something went wrong. You must agree before declining.');
        }
      }

    return(
        <>
          <>
            <Paper style={{maxHeight: 300, overflow: 'auto'}}>
                <Table>
                  <TableHead>

                  </TableHead>
                  <TableBody>
                    <TableRow style= {{backgroundColor:'#9BB8CD'}}>
                      <TableCell >ID</TableCell> 
                      <TableCell >Upload Date</TableCell>
                      <TableCell >Last Update</TableCell>
                      <TableCell >Status</TableCell>
                      <TableCell >Action</TableCell>
                    </TableRow>
                    
                    <TableRow>
                      <TableCell>{data?.autoId}</TableCell>
                      <TableCell>{new Date(data?.createdAt).toLocaleDateString("en-GB")}</TableCell>
                      <TableCell>{new Date(data?.updatedAt).toLocaleDateString("en-GB")}</TableCell>
                      <TableCell>  {data?.status === 1 ? "Approved" : data?.status === 0 ? "Not Approved" : data?.status === 4 ? "Declined" :data?.status === 2 ? "Claim Lodged" :data?.status === 3 ? "Settled" :data?.status === 5 ? "CMSF Declined" :''}</TableCell>
                      <TableCell>
                    

                    {/* {canAccessApproveInwardDividend && <Button
                        variant="contained"
                        size="medium"
                        color="primary"
                        style={{ marginRight: '10px' }}
                        disabled={data?.status ===1 || isLoading}
                        onClick={approveHandler}
                        disabled={stateOfDividend !== 0}
                      >
                        Approve
                      </Button>}  */}

                    {canAccessApproveInwardDividend && <Button
                        variant="contained"
                        size="medium"
                        color="secondary"
                        style={{ marginRight: '10px' }}
                        disabled={data?.status ===1 || isLoading}
                        onClick={declineHandler}
                        disabled={stateOfDividend !== 0}
                      >
                        Decline
                      </Button>} 

                      </TableCell>
                    </TableRow>      
                  </TableBody>
                </Table>
            </Paper>
            <h1 style={{marginBottom: "10px"}}> </h1>
          </>

          <Grid container spacing={4}
          // style={{display: 'flex',flexDirection: 'row'}}
          >
            <Grid item lg={6} md={6} sm={12} xs={12}>
              <Paper 
              // style={{display: 'flex',flexDirection: 'row',margin: '7px',width: '49%',height: '80%',borderRadius: 20, borderColor: '#a4de6c',borderBottomWidth: '10px',alignItems:'center'}}
              >
                    <Table size="">
                      <TableHead>
                      </TableHead>
                        <TableBody>
                          <TableRow>
                              <TableCell style={{dispaly: "flex" , fontSize:"20px", backgroundColor:"#96B6C5",}}></TableCell>
                              <TableCell style={{dispaly: "flex" ,textAlign: "leftalign", fontSize:"20px", backgroundColor:"#96B6C5",}}><b>Mandatory Information</b></TableCell>
                            </TableRow>
                            <TableRow>
                              <TableCell>ID</TableCell>
                              <TableCell>{data?.autoId}</TableCell>
                            </TableRow>
                            <TableRow>
                              <TableCell>Full Name</TableCell>
                              <TableCell>{data?.fullName}</TableCell>
                            </TableRow>
                            <TableRow>
                              <TableCell>BO Id</TableCell>
                              <TableCell>{data?.boID}</TableCell>
                            </TableRow>
                            <TableRow>
                              <TableCell>Folio Number</TableCell>
                              <TableCell>{data?.folioNumber}</TableCell>
                            </TableRow>
                            <TableRow>
                              <TableCell>Dividend Year</TableCell>
                              <TableCell>{data?.yearDividend}</TableCell>
                            </TableRow>
                            <TableRow>
                              <TableCell>Investors Dividend Type</TableCell>
                              <TableCell>{data.investorsdividendType==='StockRightShare'? 'Stock Right Share' 
                                    :data.investorsdividendType==='CashDividend'? 'Cash Dividend' 
                                    :data.investorsdividendType==='FCCashDividend'? 'FC Cash Dividend'
                                    :data.investorsdividendType==='PublicSubscriptionMoney' ? 'Public Subscription Money' 
                                    :data.investorsdividendType==='FCPublicSubscriptionMoney'? 'FC Public Subscription Money'
                                    :data.investorsdividendType==='StockBonusShare'? 'Stock Bonus Share' 
                                    :data.investorsdividendType==='FractionDividend'? 'Fraction Dividend' 
                                    :data.investorsdividendType==='DividendOnDividend'? 'Dividend On Dividend' 
                                    : 'N/A'}</TableCell>           
                            </TableRow>
                            <TableRow>
                              <TableCell>Shareholding</TableCell>
                              <TableCell>{data?.shareholding}</TableCell>
                            </TableRow>
                            <TableRow>
                              <TableCell>Stock Dividend</TableCell>
                              <TableCell>{data?.nofshare}</TableCell>
                            </TableRow>
                            {/* <TableRow>
                              <TableCell>Share Holding</TableCell>
                              <TableCell>{data?.nofshare}</TableCell>
                            </TableRow> */}
                            <TableRow>
                              <TableCell>Gross Cash Dividend</TableCell>
                              <TableCell>{data?.grossDividend}</TableCell>
                            </TableRow>
                            <TableRow>
                              <TableCell>TDS</TableCell>
                              <TableCell>{data?.tds}</TableCell>
                            </TableRow>
                            <TableRow>
                              <TableCell>Net Cash Dividend</TableCell>
                              <TableCell>{data?.netDividend}</TableCell>
                            </TableRow>
                            
                          </TableBody>
                        </Table>
              </Paper>
            </Grid>

            <Grid item lg={6} md={6} sm={12} xs={12}>  
              <Paper 
              // style={{display: 'flex',flexDirection: 'row',margin: '7px',width: '49%',borderRadius: 20, borderColor: '#a4de6c',borderBottomWidth: '10px',alignItems:'center'}}
              >
                    <Table>
                      <TableHead>
                      </TableHead>
                        <TableBody>
                          <TableRow>
                            <TableCell style={{dispaly: "flex" , fontSize:"27px", backgroundColor:"#96B6C5",}}></TableCell>
                            <TableCell style={{dispaly: "flex" ,textAlign: "leftalign", fontSize:"20px", backgroundColor:"#96B6C5",}}><b>Optional Information</b></TableCell>
                          </TableRow>
                          <TableRow>
                                  <TableCell>Father Name</TableCell>
                                  <TableCell>{data?.fathersName}</TableCell>
                          </TableRow>
                          <TableRow>
                                  <TableCell>Mother Name</TableCell>
                                  <TableCell>{data?.mothersName}</TableCell>
                          </TableRow>
                          <TableRow>
                                  <TableCell>Date of Birth</TableCell>
                                  <TableCell>{data?.DoB}</TableCell>
                          </TableRow>
                          <TableRow>
                            <TableCell>Phone Number</TableCell>
                                  <TableCell>{data?.phoneNumber}</TableCell>
                                </TableRow>
                                <TableRow>
                                  <TableCell>Address</TableCell>
                                  <TableCell>{data?.address}</TableCell>
                                </TableRow>
                              
                                <TableRow>
                                  <TableCell>Warrant Number</TableCell>
                                  <TableCell>{data?.warrantNumber}</TableCell>
                                </TableRow>
                                <TableRow>
                                  <TableCell>Account Name</TableCell>
                                  <TableCell>{data?.AccountName}</TableCell>
                                </TableRow>
                                <TableRow>
                                  <TableCell>Account Number</TableCell>
                                  <TableCell>{data?.AccountNumber}</TableCell>
                                </TableRow>

                                <TableRow>
                                  <TableCell>Bank Name</TableCell>
                                  <TableCell>{data?.BankName}</TableCell>
                                </TableRow>
                                <TableRow>
                                  <TableCell>Branch Name</TableCell>
                                  <TableCell>{data?.BranchName}</TableCell>
                                </TableRow>
                                
                                <TableRow>
                                  <TableCell>Routing Number</TableCell>
                                  <TableCell>{data?.RoutingNumber}</TableCell>
                                </TableRow>
                                <TableRow>
                                  <TableCell>Remarks</TableCell>
                                  <TableCell>{data?.remarks}</TableCell>
                                </TableRow>
                            
                                
                              
                              
                              </TableBody>
                          </Table>
              </Paper>
            </Grid>
          </Grid>
        </>
    )
}