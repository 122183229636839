import { Button, FormControl, Grid, InputLabel, MenuItem, Select ,Card} from "@material-ui/core";
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useFilters, useGlobalFilter, usePagination, useTable } from 'react-table';
import { useGetIssuerInfoQuery } from "../../../features/inwardDividend/inwardDividendAPI";
import axios from "../../../utils/axios";
// import Select from 'react-select'

const options = [{ tradecode: "No Data Available", name: "" },
// { tradecode: "No Data Available", name: "" },
];


function Table({ getDataSync, columns, data, setDataStore, setCurrentPage, currentPage, numberOfPage, showPageSize, setShowPageSize,setNumberOfpage,params }) {
    const {  data:issuer} = useGetIssuerInfoQuery();
    const { t } = useTranslation();
    const [statusValue,setStatusValue]= useState("")
    const [dividendType,setDividendType]= useState("")
    const [newData, setNewData] = useState([]);
    const [key, setKey] = useState(Date.now());

    useEffect(()=>
  
  {axios.get('./issuer/get-info')
  .then((res)=>{
    setNewData(res.data.payload)
  })
  .catch((err) =>{
    alert(err.response.data.message)
  })
},[])

  // console.log(newData)

    const newData2 = newData?newData:options
    var sortedNewData = newData2
    // if (newData2) {
    //   sortedNewData = newData2.slice().sort((a, b) => {
    //     if (a.tradecode < b.tradecode) {
    //       return -1;
    //     }
    //   });
     
    // }

    const defaultColumn = React.useMemo(
        () => ({
            Filter: "",
        }),
        []
    )

    const issuerData = issuer?.payload
    //console.log(issuerData);
    const issuerData2 = issuerData;
    //console.log(issuerData2);
    var sortedIssuerData = issuerData
    if (issuerData2) {
     sortedIssuerData = issuerData2.slice().sort((a, b) => {
        if (a.tradecode < b.tradecode) {
          return -1;
        }
      });
     
    }
    
//console.log(sortedIssuerData);
const ITEM_HEIGHT = 100;
    const ITEM_PADDING_TOP = 8;
    const MenuProps = {
        PaperProps: {
          style: {
            //maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            // width: 250,
            //height: 100,
            overflowY: 'scroll',
            overflowX: 'scroll'
          },
        },
      };

    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        rows,
        prepareRow,
        state,
        canPreviousPage,
        canNextPage,
        pageOptions,
        pageCount,
        gotoPage,
        nextPage,
        previousPage,
        setPageSize,
        preGlobalFilteredRows,
        setGlobalFilter,
        state: { pageIndex, pageSize },
    } = useTable(
        {
            columns,
            data,
            defaultColumn,
        },
        useFilters,
        useGlobalFilter,
        usePagination,
    )

    const nextPageHandler = () =>{        
        if(numberOfPage > currentPage){
            setCurrentPage(++currentPage);      
        }
    }

    const prevPageHandler = () =>{        
        if(currentPage > 1){
            setCurrentPage(--currentPage);
        }
    }
    
    const handleFirstPage = () =>{
        if(currentPage !== 1){
            setCurrentPage(1)
        }
    }

    const handleLastPage = () =>{
        if(currentPage !== numberOfPage){
            setCurrentPage(numberOfPage)
        }
    }

    const goToPageHandler = (e) =>{
        getDataSync(e,showPageSize, remarksValue,dateValue, issuerValue, totalCashValue, totalFcValue, totalStockValue)
    }

    

    const pSize= [5, 10, 20, 30, 40, 50];

    const [fullName, setFullName] = useState("")
    const [folioNumberValue, setFolioNumberValue] = useState("")
    const [boIDValue, setBoIDValue] = useState("")
    const [netDividendValue, setNetDividendValue] = useState("")
    const [dateValue, setDateValue] = useState("")
    const [issuerValue, setIssuerValue] = useState("")
    const [totalCashValue, setTotalCashValue] = useState("")
    const [totalFcValue, setTotalFcValue] = useState("")
   // const [totalStockValue, setStockValue] = useState("")
    const [remarksValue, setRemarksValue] = useState("")
    const [emptyValue, setEmptyValue] = useState("")


    const [shareUpper, setShareUpper] = useState()
    const [shareLower, setShareLower] = useState()

    const [netDividendUpper, setNetDividendUpper] = useState()
    const [netDividendLower, setNetDividendLower] = useState()

    const [totalCashSum, setTotalCashSum] = useState(0)
    const [totalStockSum, setTotalStockSum] = useState(0)
    

    const totalStockValue = shareLower && shareUpper ? [shareLower, shareUpper] : null;
    const totalNetDividendValue = netDividendLower && netDividendUpper ? [netDividendLower, netDividendUpper] : null;

    
   
    //const totalStockValue = console.log(JSON.stringify(totalStockValuei));
    //const shareArray = [shareLower,shareUpper];
    // const convertedArray = shareArray.JSON
   //console.log(JSON.stringify(totalStockValue));

    const paramsData = {
        page: currentPage,
        take: showPageSize,
        fullName: fullName,
        boID: boIDValue,
        folioNumber: folioNumberValue,
        netDividend: totalNetDividendValue,
        status:statusValue,
        nofshare: totalStockValue,
        yearDividend: dateValue,
        issuerId: issuerValue,
        investorsdividendType:dividendType,
    }
    const paramsDataSearch = {
        page: currentPage,
        take: 100,
        fullName: fullName,
        boID: boIDValue,
        folioNumber: folioNumberValue,
        netDividend: totalNetDividendValue,
        status:statusValue,
        nofshare: totalStockValue,
        yearDividend: dateValue,
        issuerId: issuerValue,
        investorsdividendType:dividendType,
    }
    //console.log(paramsData)


    const searchResethandler = () =>{
        setFullName("");
        setIssuerValue("")
        setBoIDValue("")
        setKey(Date.now());
        setFolioNumberValue("")
        setShareLower("")
        setShareUpper("")
        setStatusValue("")
        setDividendType("")
        //setNetDividendValue("") 
        setNetDividendUpper("") 
        setNetDividendLower("")
    }

    const searchHandler = () =>{
        axios.post(`investor-informations/list`, paramsDataSearch)
        .then((res) =>{
            setDataStore(res.data.payload)
        
            let totalCash = res.data.payload.reduce((sum, item) => sum + item.netDividend, 0);
            let totalStock = res.data.payload.reduce((sum, item) => sum + item.nofshare, 0);
            setTotalCashSum(totalCash.toFixed(2));
            setTotalStockSum(totalStock);
        })
        .catch((err) =>{
        })
    }
    
 
    // useEffect(()=>{
    //         axios.post(`investor-informations/list`, paramsData)
    //     .then((res) =>{
    //         setDataStore(res.data.payload)
        
    //         const totalCash = res.data.payload.reduce((sum, item) => sum + item.netDividend, 0);
    //         setTotalCashSum(totalCash);

    //         const totalStock = res.data.payload.reduce((sum, item) => sum + item.nofshare, 0);
    //         setTotalCashSum(totalStock);
    //         if(res.data?.page){
    //             setNumberOfpage(Math.ceil(res.data?.total/showPageSize))
    //         }
    //     })
    //     .catch((err) =>{
    //     })
            
    // },[fullName,shareUpper, shareLower,  remarksValue,dateValue, setDataStore,setCurrentPage,setNumberOfpage,showPageSize,folioNumberValue,boIDValue,currentPage,issuerValue,netDividendUpper,netDividendLower,statusValue,dividendType])
    
    useEffect(()=>{
        axios.post(`investor-informations/list`, paramsData)
    .then((res) =>{
        setDataStore(res.data.payload)
        let totalCash = res.data.payload.reduce((sum, item) => sum + item.netDividend, 0);
        let totalStock = res.data.payload.reduce((sum, item) => sum + item.nofshare, 0);
            setTotalCashSum(totalCash.toFixed(2));
            setTotalStockSum(totalStock);
        if(res.data?.page){
            setNumberOfpage(Math.ceil(res.data?.total/showPageSize))
        }
    })
    .catch((err) =>{
    })
        
},[setDataStore,setCurrentPage,setNumberOfpage,showPageSize,currentPage])
   
    
    useEffect(()=>{
       
        setCurrentPage(1)
    },[folioNumberValue,boIDValue,issuerValue,netDividendUpper,netDividendLower,shareUpper,shareLower,fullName,statusValue])
    return (
        
        <div>
           
            <Grid container spacing={1}>

            <Grid item lg={2} md={2} sm={2} xs={12}>
          <FormControl fullWidth >
                <InputLabel id="issuer-id" ></InputLabel>
                {/* <Select 
                required
                    labelId="issuer-id"
                    id="demo-simple-select"
                    defaultChecked
                    value={issuerValue}
                    label="Select Issuer"
                    onChange={(e) => setIssuerValue(e.target.value)}
                    MenuProps={MenuProps} 
                    >
                   
                    {sortedIssuerData?.map((i)=><MenuItem value={i.id} key={i.id}>({i.tradecode}) {i.name}</MenuItem>)}
                    
                </Select> */}
                {/* <Select sorted options={options} /> */}

                <Autocomplete
                 key={key}
                //  value={issuerValue}
                label="Select Issuer"
                onChange={(event, newValue) => {
                    setIssuerValue( newValue.id);
                }}
                disableClearable
                // options={top100Films}
                // getOptionLabel={(option) => "(" + option.title + ") " +option.year}
                options={sortedNewData?sortedNewData:newData?newData:options}
                getOptionLabel={(option) => option.name + " (" + option.tradecode + ") "  }
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Select Issuer Name"
                    // margin="normal"
                    // variant="outlined"
                    // InputProps={{ ...params.InputProps, type: 'search' }}
          />
                )}
            />
              </FormControl>
          
          
        </Grid>
        <Grid item lg={2} md={2} sm={2} xs={12}>
          
            <FormControl fullWidth >
            
                    <InputLabel id="issuer-id" >Search by dividend status</InputLabel>
                        <Select 
                        labelId="status"
                        id="status"
                        value={statusValue}
                        label="Select status"
                        onChange={(e) => setStatusValue(e.target.value)}  
                        >
                        <MenuItem value='0' key='Initiated'>Not Approved</MenuItem>
                        <MenuItem value='1' key='Received by Issuer'>Approved</MenuItem>
                        {/* <MenuItem value='2' key='Approved by Issuer'>Claim Lodged</MenuItem>
                        <MenuItem value='3' key='Received by CMSF'>Settled</MenuItem>
                        <MenuItem value='4' key='Received by CMSF'>Declined by Issuer</MenuItem>
                        <MenuItem value='5' key='Received by CMSF'> CMSF Declined</MenuItem> */}
                    
                    </Select>
            </FormControl> 
        </Grid>
        {/* <Grid item lg={2} md={2} sm={2} xs={12}>  
            <FormControl fullWidth >
                <InputLabel id="issuer-id" >Search by dividend Type</InputLabel>
                    <Select 
                      labelId="status"
                      id="status"
                      value={dividendType}
                      label="Select status"
                      onChange={(e) => setDividendType(e.target.value)}  
                      >
                      <MenuItem value='CashDividend' key='CashDividend'>Cash Dividend</MenuItem>
                      <MenuItem value='StcokDividend' key='StcokDividend'>Stcok Dividend</MenuItem>  
                  </Select>
            </FormControl> 
        </Grid> */}
        <Grid item lg={2} md={2} sm={2} xs={12}>
          <span>
            Full Name:{' '}
            <input
                className="form-control"
                value={fullName || ""}
                onChange={e => {
                    setFullName(e.target.value);
                }}
                placeholder={` search...`}
            />
        </span>
          </Grid>
          <Grid item lg={2} md={2} sm={2} xs={12}>
          <span>
            BO Id:{' '}
            <input
                className="form-control"
                type='number'
                value={boIDValue || ""}
                onChange={e => {
                    setBoIDValue(e.target.value);
                }}
                placeholder={` search...`}
            />
        </span>
          </Grid>
          <Grid item lg={2} md={2} sm={2} xs={12}>
          <span>
            Folio Id:{' '}
            <input
                className="form-control"
                // type='number'
                value={folioNumberValue || ""}
                onChange={e => {
                    setFolioNumberValue(e.target.value);
                }}
                placeholder={`search...`}
            />
        </span>
          </Grid>
          <Grid item lg={1} md={2} sm={2} xs={12}>
          <span>
            No of Share:{' '}
            <input
                className="form-control"
                type='number'
                value={shareLower || "" }
                onChange={e => {
                    // noOfSharesHandler();
                    setShareLower(parseFloat(e.target.value))
                    //setStockValue(`${shareLower}, ${shareUpper}`);
                   
                }}
                placeholder={`from...`}
            />
        </span>
        <span>
            {' '}
            <input style={{marginTop:'4px'}}
                className="form-control"
                //type='number'
                value={shareUpper|| "" }
                onChange={e => {
                    // noOfSharesHandler();
                    setShareUpper(parseFloat(e.target.value));
                  //  setStockValue(`${shareLower}, ${shareUpper}`);
                   
                }}
                placeholder={` to...`}
            />
             
        </span>
        
          </Grid>
          <Grid item lg={1} md={2} sm={2} xs={12}>
          <span>
          Net Dividend:{' '}
            <input
                className="form-control"
                type='number'
                step="0.1"
                min='0.0'
                value={netDividendLower || ""}
                onChange={e => {
                    setNetDividendLower(parseFloat(e.target.value));
                }}
                placeholder={` from...`}
            />
        </span>
        <span>
          {' '}
            <input
                className="form-control"
                type='number'
                value={netDividendUpper || ""}
                onChange={e => {
                    setNetDividendUpper(parseFloat(e.target.value));
                }}
                placeholder={` to...`}
            />
        </span>
        
          </Grid>
          <Grid item lg={1} md={2} sm={2} xs={12}>
          <span>
          <Button
           variant="contained"
                 color="primary"
                 size="medium"
                 style={{margin:"10px", marginTop:"20px"}}
                 onClick={searchResethandler}
                  >{ t('common.reset')}</Button>
        </span>
        </Grid>

        <Grid item lg={1} md={2} sm={2} xs={12}>
          <span>
          <Button
           variant="contained"
                 color="primary"
                 size="medium"
                 style={{margin:"10px", marginTop:"20px"}}
                 onClick={searchHandler}
                  >Search</Button>
        </span>
        </Grid>
        <Card style={{ 
                 display: "flex",
                 justifyContent: "flex-end", // Push content to the right
                //  justifyContent: "center", // Push content to the right
                 alignItems: "center",
                 padding: "8px 16px", // Add spacing inside the card
                 marginBottom: "8px", // Space between rows
                 width: "100%", // Full-width like a table row
                 backgroundColor: "#f9f9f9", // Optional: Light background color
                 border: "1px solid #ddd", // Border to mimic a table row
                 borderRadius: "4px", // Slight rounding
            }}> <div
            style={{
                fontSize: "20px", // Increase the font size
                fontWeight: "bold", // Optional: Make the text bold
                marginRight:'220px'
            }}
        >
            Total Net Dividend : {totalCashSum} BDT
        </div></Card>     
           <Card style={{ 
                 display: "flex",
                 justifyContent: "flex-end", 
                 alignItems: "center",
                 padding: "8px 16px", 
                 marginBottom: "8px", 
                 width: "100%", 
                 backgroundColor: "#f9f9f9", 
                 border: "1px solid #ddd", 
                 borderRadius: "4px", 
            }}>  <div
            style={{
                fontSize: "20px", // Increase the font size
                fontWeight: "bold", // Optional: Make the text bold
                marginRight:'220px'
            }}
        >
            Total No of Share: {totalStockSum} Nos
        </div></Card>   
          </Grid>
           
            {/* <table className="table" {...getTableProps()}>
                <thead>
                    {headerGroups.map(headerGroup => (
                        <tr {...headerGroup.getHeaderGroupProps()}>
                            {headerGroup.headers.map(column => (
                                <th {...column.getHeaderProps()}>
                                    {column.render('Header')}
                                    <div>{column.canFilter ? column.render('Filter') : null}</div>
                                </th>
                            ))}
                        </tr>
                    ))}
                </thead>
                <tbody {...getTableBodyProps()}>
                    {rows.map((row, i) => {
                        prepareRow(row)
                        return (
                            <tr {...row.getRowProps()}>
                                {row.cells.map(cell => {
                                    return <td {...cell.getCellProps()}>{cell.render('Cell')}</td>
                                })}
                            </tr>
                        )
                    })}
                </tbody>
            </table> */}
             <Grid container style={{ overflowX: 'auto'}}>
      <table className="table" {...getTableProps()}>
        <thead>
          {headerGroups.map(headerGroup => (
            <tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map(column => (
                <th {...column.getHeaderProps()}>
                  {column.render('Header')}
                  <div>{column.canFilter ? column.render('Filter') : null}</div>
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
  {rows.map((row, i) => {
    prepareRow(row);
    return (
      <tr {...row.getRowProps()}>
        {row.cells.map(cell => {
          return (
            <td 
            style={{wordWrap: 'break-word',
            whiteSpace: 'none',
            maxWidth: '350px'}} 
                {...cell.getCellProps()} data-label={cell.column.render('Header')}>
              {cell.render('Cell')}
            </td>
          );
        })}
      </tr>
    );
  })}
</tbody>
        
      </table>
    </Grid>

            <ul className="pagination">
                <li className="page-item" onClick={handleFirstPage} disabled={!canPreviousPage}>
                    <a className="page-link">First</a>
                </li>
                <li className="page-item" onClick={prevPageHandler}
                 disabled={!canPreviousPage}
                 >
                    <a className="page-link">{'<'}</a>
                </li>
                <li className="page-item" onClick={nextPageHandler}
                 disabled={!canNextPage}
                 >
                    <a className="page-link">{'>'}</a>
                </li>
                <li className="page-item" onClick={handleLastPage} disabled={!canNextPage}>
                    <a className="page-link">Last</a>
                </li>
                <li>
                    <a className="page-link">
                        Page{' '}
                        <strong>
                            {currentPage} of {numberOfPage}
                        </strong>{' '}
                    </a>
                </li>
                <li>
                    <a className="page-link">
                        <input
                            className="form-control"
                            type="number"
                            defaultValue={pageIndex + 1}
                            onChange={e => {
                                goToPageHandler(e.target.value)
                            }}
                            style={{ width: '100px', height: '20px' }}
                        />
                    </a>
                </li>{' '}
                <select
                    className="form-control"
                    value={showPageSize}
                    onChange={e => {
                        setShowPageSize(Number(e.target.value))
                    }}
                    style={{ width: '120px', height: '38px' }}
                >
                    {pSize.map(pageSize => (
                        <option key={pageSize} value={pageSize} >
                            Show {pageSize}
                        </option>
                    ))}
                </select>
            </ul>
  
        </div>
    )
}

export default Table;