import { Box, Button, Grid, IconButton, InputAdornment, Link, OutlinedInput, Paper, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { Lock, Phone, Visibility, VisibilityOff } from '@material-ui/icons';
import React, { useEffect, useState } from 'react';
import ReCAPTCHA from 'react-google-recaptcha';
import { Link as RouterLink } from 'react-router-dom';
import { loginUser, useUserDispatch } from "../../context/UserContext";
import Logo from './AuthBackground';
import CmsfLogo from './cmsf.jpg';
import './style.css';
// import Visibility from '@mui/icons-material/Visibility';
// import VisibilityOff from '@mui/icons-material/VisibilityOff';



const useStyles = makeStyles((theme) => ({
  root: {
    height: '100vh',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',

  },
  paper: {
    position: 'relative',
    padding: theme.spacing(4),
    maxWidth: 600,
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    // height:'600px'
  },
  form: {
    width: '100%',
    marginTop: theme.spacing(3),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  modalContent: {
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(4),
    outline: 'none',
    maxWidth: 400,
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
  },
  closeButton: {
    position: 'absolute',
    top: theme.spacing(1),
    right: theme.spacing(1),
  },
  logo: {
    position: 'absolute',
    top: theme.spacing(3),
    left: theme.spacing(3),
    width: 180,
    height: 80,
  },
}));


const LoginComponent = (props) => {
  const classes = useStyles();
  var userDispatch = useUserDispatch();
  const [phoneNumber, setPhoneNumber] = useState('');
  const [password, setPassword] = useState('');
  const [captchaValue, setCaptchaValue] = useState('');
  const [phoneNumberError, setPhoneNumberError] = useState('');
  const [passwordError, setPasswordError] = useState('');
  const [captchaError, setCaptchaError] = useState('');
  const [isFormValid, setIsFormValid] = useState(false);
  const [isError, setIsError] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  
  
  useEffect(() =>{
    let hasErrors = false;

    if (!validatePhoneNumber(phoneNumber)) {
      if(phoneNumber.length < 1){
        setPhoneNumberError('');
        hasErrors = true;
      }else{
        setPhoneNumberError('Invalid phone number. Please enter a valid phone number.');
      hasErrors = true;
      }
    } else {
      setPhoneNumberError('');
    }

    if (!validatePassword(password)) {
      if(password.length < 1){
        setPasswordError('');
      hasErrors = true;
      }else{
        setPasswordError('Password at least 6 characters');
      hasErrors = true;
      }
    } else {
      setPasswordError('');
    }

    if (!captchaValue) {
      hasErrors = true;
    } else {
      setCaptchaError('');
    }

    if (hasErrors) {
      setIsFormValid(true)
    }else{
      setIsFormValid(false)
    }
  },[phoneNumber, password, captchaValue])

  const handleFormSubmit = (e) => {
    // debugger;
    e.preventDefault();
    setIsLoading(true);
   if(captchaValue === ''){
    setCaptchaError('Please complete the reCAPTCHA.');
    setIsLoading(false);
   }
   if(phoneNumber === ''){
    setPhoneNumberError('Please enter a phone number');
    setIsLoading(false);
   }
   if(password === ''){
    setPasswordError('Please enter a password');
    setIsLoading(false);
   }

    if(!isFormValid){
      loginUser(             
        userDispatch,
        phoneNumber,
        password,
        props.history,
        setIsLoading,
        setIsError
      );
    }
  };

  const validatePhoneNumber = (phoneNumber) => {
    const phoneNumberRegex = /^\d{11}$/;
    return phoneNumberRegex.test(phoneNumber);
  };

  const handleClickShowPassword = () => {setShowPassword((prevState) => !prevState)};
  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const validatePassword = (password) => {
    return password.length >= 6;
  };

  const handleCaptchaChange = (value) => {
    setCaptchaValue(value);
    setCaptchaError('');
  };

  return (
    <div >
      <Box className="left-align">
      <Logo />
    </Box>
    
          
     
    <div className={classes.root}>
      <img  src={CmsfLogo} alt="Logo" className={classes.logo} /> 
      <Box style={{transform:'scale(.8)'}}>
      <Typography style={{textAlign:'center',  color:'blue', marginTop:"20px"}} variant='h1' component='h1'>Welcome to CMSF</Typography>
      {/* <Box>
      <Paper>
        <Box style={{textAlign:'center', backgroundColor:'blue', color:'white'}}>
          <Typography variant='h2' component='h1'>Welcome to CMSF</Typography>
        </Box>
        </Paper>
      </Box> */}
      <Box>
      <Paper className={classes.paper}>
        
        <Typography style={{color:'black'}} variant="h5" component="h1" gutterBottom>
          Log in to INSAF
        </Typography>
        <form onSubmit={handleFormSubmit}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <OutlinedInput
            fullWidth
            id="phone"
            type="number"
            value={phoneNumber}
            onChange={(e) => setPhoneNumber(e.target.value)}
            placeholder="Phone Number"
            startAdornment={
              <InputAdornment position="start">
                <Phone />
              </InputAdornment>
            }
          />
          {phoneNumberError && <Typography color="error">{phoneNumberError}</Typography>}
        </Grid>
        <Grid item xs={12}>
          <OutlinedInput
            fullWidth
            id="password"
            type={showPassword ? 'text' : 'password'}
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            placeholder="Password"
            onPaste={(e) => e.preventDefault()}
            startAdornment={
              <InputAdornment position="start">
                <Lock />
              </InputAdornment>
            }
            endAdornment={
              <InputAdornment position="end">
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={handleClickShowPassword}
                  onMouseDown={handleMouseDownPassword}
                  edge="end"
                >
                  {showPassword ? <Visibility /> : <VisibilityOff />}
                </IconButton>
              </InputAdornment>
            }
          />
          {passwordError && <Typography color="error">{passwordError}</Typography>}
          {isError && <Typography color="error">Phone number or password is incorrect!</Typography>}
        </Grid>
      </Grid>
      <p></p>
      <Grid container spacing={2}>
        <Box style={{ margin: '20px 0px 15px 8px' }}>
          <ReCAPTCHA
            sitekey="6LcCYFAmAAAAAFfMWhfCC5jN-2BfENqCpckYmXG1"
            onChange={handleCaptchaChange}
          />
          {captchaError && <Typography color="error">{captchaError}</Typography>}
        </Box>
      </Grid>

      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Button
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
            disabled={isLoading}
          >
           Login
          </Button>
        </Grid>
        {/* <Grid item xs={6}>
          <Button
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
          >
            CMSF Login
          </Button>
        </Grid> */}
      </Grid>
      <Grid container justify="flex-end">
        <Grid item>
          <Link component={RouterLink} to="forget-password" variant="body2">
            Forgot Password?
          </Link>
        </Grid>
      </Grid>
      <Grid container className={classes.submit}>
        <Grid item lg={12} sm={12}>
          <Typography style={{ textAlign: 'center' }} variant="body2" color="textSecondary">
            Don't have an account?
          </Typography>
        </Grid>
        <Grid item style={{ textAlign: 'center' }} lg={12} sm={12}>
          <Button
            component={RouterLink}
            to="registration"
            variant="text"
            color="primary"
          >
            Create New Account
          </Button>
        </Grid>
      </Grid>
    </form>
      </Paper>
      </Box>
      </Box>
    </div>
    </div>
  );
};

export default LoginComponent;
