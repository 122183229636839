import { Box, MenuItem } from "@material-ui/core";
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import { Autocomplete, Button, TextField } from '@mui/material';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import 'dayjs/locale/en-gb';
import { asBlob, generateCsv, mkConfig } from 'export-to-csv'; //or use your library of choice here
import {
  MaterialReactTable,
  createMRTColumnHelper,
  useMaterialReactTable,
} from 'material-react-table';
import React, { useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import LoaderBar from "../../components/LoaderBar/LoaderBar";
import PageTitle from "../../components/PageTitle/PageTitle";
import axios from '../../utils/axios';









const SettledDividend = () => {

  const [data,setData] = useState([]);
  const [dividendType,setDividendType] = useState([]);
  const [branchList,setBranchList] = useState([]);
  const [issuerList,setIssuerList] = useState([]);
  const [isLoading,setIsLoading] = useState(false);
  const [selectedIssuer,setSelectedIssuer] = useState("");
  const [selectedToDate, setSelectedToDate] = useState(null);
  const [selectedFromDate, setSelectedFromDate] = useState(null);
  const [selectedDividendType,setSelectedDividendType] = useState("Cash");
  let accounts = [{id:1,label:"Joint Account"},{id:2,label:"Individual Account"}] ;
  
let claimtypelist =[{id:1,label:"Self"},{id:2,label:"Authorized Person/Power of Attorney"},{id:3,label:"Successor/Nominee"}] 
  const { register, control,setValue } = useForm({});
  const handleShowData = async () => {
    // fetchData();
    // console.log(selectedIssuer,selectedToDate,selectedFromDate,selectedDividendType)
    const submitJson = {issuerId:selectedIssuer,dividendType:selectedDividendType,fromDate:selectedFromDate,toDate:selectedToDate}
    getSettledDividendList(submitJson);
  }

    
    const getSettledDividendList = async (submitJson) =>{
      setIsLoading(true);
      try
      {
        const response = await axios.post('/settlement/get-by-settlement-date',submitJson)
        let res = response.data.payload;
        let modifiedData =[]
        res.map((item,index) =>{
          const rowWithFetchedId = dividendType.find(row => row.id === item.investor_dividend_type_id);
          const labelToShow = rowWithFetchedId ? rowWithFetchedId.investorDividendType+ "("+rowWithFetchedId.dividendType+")"  : item.investor_dividend_type_id;
          const accountTypeidfound = accounts.find(row=>row.id===item.account_type);
          const accountTypeLabel = accountTypeidfound ? accountTypeidfound.label : item.account_type;
          const YearList = item.years.join(", ");
          const applicationdateformat = new Date(item.application_date).toISOString().split("T")[0];
          const recievedateformat = new Date(item.receive_date).toISOString().split("T")[0];
          const entrydateformat = new Date(item.createdat).toISOString().split("T")[0];
          const updatedateformat = new Date(item.updatedat).toISOString().split("T")[0];
          const claimTypeText = claimtypelist.find(row=>row.id===item.claimtype)?.label ;
          delete item.years;
          modifiedData.push({...item,investorDividendType:labelToShow,accountTypeLabel:accountTypeLabel,yearList:YearList,index:index+1,claimTypeText:claimTypeText,application_date:applicationdateformat,receive_date:recievedateformat,createdat:entrydateformat,updatedat:updatedateformat})

        })
        setData(modifiedData);
        setIsLoading(false);
      }
      catch(error)
      {
        setIsLoading(false);
        console.error('Error fetching ready to settled list:', error.response?.data?.message || error.message);
      }
      
    }



  useEffect(() => {
    
    try {

       axios.get('./issuer/get-info')
      .then((res)=>{
        setIssuerList([{ id: 'All', name: 'All',tradecode:'All'},...res?.data?.payload]);
        // setSelectedIssuer('All');
      })
      .catch((err) =>{
        console.log(err)
      })
      // Fetch dividend types
       axios.get('/inward-dividend/get-dividend-types')
       .then((response)=>{
        let res = response.data.payload;
        console.log(res);
        setDividendType(response.data.payload);
       })
       .catch((e)=>{
        console.error(e);
       })
       
        
    } catch (error) {
      console.error('Error fetching data:', error);
    
  }
  // setSelectedIssuer('All');
  setSelectedDividendType('Cash');
  setSelectedFromDate("All");
  setSelectedToDate("All");
  
  
  }, [])


  
  const columnHelper = createMRTColumnHelper();

  const columns = [
    
    columnHelper.accessor('index', {
      header: 'SL',
      size: 40,
    }),
    // columnHelper.accessor('settlement_id', {
    //   header: 'Settlement ID',
    //   size: 40,
    // }),
    columnHelper.accessor('name', {
      header: 'Investor Name',
    }),
    columnHelper.accessor('issuer_name', {
      header: 'Issuer Name',
      size: 120,
    }),
    columnHelper.accessor('investorDividendType', {
      header: 'Investor Dividend Type',
      size: 220,
    }),
    columnHelper.accessor('claimTypeText', {
      header: 'Claim Type',
      size: 220,
    }),
    columnHelper.accessor('claimantname', {
      header: 'Claimant Name',
      size: 220,
    }),
    columnHelper.accessor('claimantnid', {
      header: 'Claimant NID',
      size: 220,
    }),
    columnHelper.accessor('settlementdate', {
      header: 'CMSF\'s settlement date',
      size: 220,
    }),
    columnHelper.accessor('bo_id', {
      header: 'BOID',
      size: 120,
    }),
    columnHelper.accessor('folio_number', {
      header: 'FOLIO NO',
  
    }),
    columnHelper.accessor('nid', {
      header: 'NID',
  
    }),
    columnHelper.accessor('passport', {
      header: 'Passport No',
  
    }),
    
    columnHelper.accessor('bankname', {
      header: 'Bank Name',
      size: 220,
    }),
    columnHelper.accessor('branchname', {
      header: 'Branch',
      size: 220,
    }),
    columnHelper.accessor('bankaccountno', {
      header: 'Account No',
      size: 220,
    }),
    columnHelper.accessor('routingno', {
      header: 'Routing No',
      size: 220,
    }),
    columnHelper.accessor('dpname', {
      header: 'DP House',
      size: 220,
    }),
    columnHelper.accessor('dpid', {
      header: 'DP House ID',
      size: 220,
    }),
    columnHelper.accessor('stockunitprice', {
      header: 'Stock Unit Price',
      size: 220,
    }),
    columnHelper.accessor('yearList', {
      header: 'Year',
      size: 220,
    }),
    columnHelper.accessor('total_amount', {
      header: 'Cash / Stock',
      size: 220,
    }),
    columnHelper.accessor('accountTypeLabel', {
      header: 'Account Type',
      size: 220,
    }),
    columnHelper.accessor('shareholder_name', {
      header: 'Second Investor\'s Name',
      size: 220,
    }),
    columnHelper.accessor('sholdernid', {
      header: 'Second Investor\'s NID',
      size: 220,
    }),
    columnHelper.accessor('sholderpassport', {
      header: 'Second Investor\'s Passport',
      size: 220,
    }),
    
    columnHelper.accessor('application_date', {
      header: 'Application Date',
      size: 220,
    }),
    columnHelper.accessor('receive_date', {
      header: 'Claim Recieve Date',
      size: 220,
    }),
    columnHelper.accessor('remarks', {
      header: 'Remarks',
      size: 220,
    }),
    columnHelper.accessor('createdby', {
      header: 'Entry By',
      size: 220,
    }),
    columnHelper.accessor('createdat', {
      header: 'Entry Date',
      size: 220,
    }),
    columnHelper.accessor('updatedby', {
      header: 'Settlement Update By',
      size: 220,
    }),
    columnHelper.accessor('updatedat', {
      header: 'Settled Update Date',
      size: 220,
    })
  ];
  
  const csvConfig = mkConfig({
    fieldSeparator: ',',
    decimalSeparator: '.',
    useKeysAsHeaders: true,
  });








  const handleExportRows = (rows) => {
    const csv = generateCsv(csvConfig)(rows);
    const blob = asBlob(csvConfig)(csv);
    const fileURL = URL.createObjectURL(blob);
    var link = document.createElement("a");
    link.href = fileURL;
    link.setAttribute("download", `Settled Dividend Rows.csv`);
    link.click();
    // download(csvConfig)(csv);
  };

  const handleExportData = () => {
    const csv = generateCsv(csvConfig)(data);
    const blob = asBlob(csvConfig)(csv);
    const fileURL = URL.createObjectURL(blob);
    var link = document.createElement("a");
    link.href = fileURL;
    link.setAttribute("download", `All Settled Dividend.csv`);
    link.click();
    // download(csvConfig)(csv);
  };

  const table = useMaterialReactTable({
    columns,
    data,
    // enableRowSelection: true,
    
    columnFilterDisplayMode: 'popover',
    paginationDisplayMode: 'pages',
    enableStickyHeader: true,
    muiPaginationProps: {
      rowsPerPageOptions: [5, 10, 15, 20, 25, 30, 50, 100, data.length]
    },
    positionToolbarAlertBanner: 'bottom',
    muiTableContainerProps: {

      style: { maxHeight: '50vh' } 

  },
    renderBottomToolbarCustomActions: ({ table }) => (
      <Box
        sx={{
          display: 'flex',
          gap: '16px',
          padding: '8px',
          flexWrap: 'wrap',
        }}
      >
        <Button
          //export all data that is currently in the table (ignore pagination, sorting, filtering, etc.)
          variant="contained"
          onClick={handleExportData}
          startIcon={<FileDownloadIcon />}
          disabled={table.getPrePaginationRowModel().rows.length === 0}
        >
          Export All Data
        </Button>
        {/* <Button
          disabled={table.getPrePaginationRowModel().rows.length === 0}
          //export all rows, including from the next page, (still respects filtering and sorting)
          onClick={() =>
            handleExportRows(data)
          }
          startIcon={<FileDownloadIcon />}
        >
          Export All Rows
        </Button>
        <Button
          disabled={table.getRowModel().rows.length === 0}
          //export all rows as seen on the screen (respects pagination, sorting, filtering, etc.)
          onClick={() => handleExportRows(table.getRowModel().rows)}
          startIcon={<FileDownloadIcon />}
        >
          Export Page Rows
        </Button>
        <Button
          disabled={
            !table.getIsSomeRowsSelected() && !table.getIsAllRowsSelected()
          }
          //only export selected rows
          onClick={() => handleExportRows(table.getSelectedRowModel().rows)}
          startIcon={<FileDownloadIcon />}
        >
          Export Selected Rows
        </Button> */}
      </Box>
    ),
  });

  return( <>
          <PageTitle title={"Compliance Report"}></PageTitle>
          <Box style={{display:"flex",marginBottom:"20px"}}>
       
          <Controller
        name="issuerId"
        control={control}
        
        render={({ field }) => (
          <Autocomplete
            {...field}
            options={issuerList}
            style={{width:"20%"}}
            getOptionLabel={(option) => `${option.name} (${option.tradecode})`}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Select Issuer"
                variant="outlined"
                fullWidth
                required
              />
            )}
            onChange={(_, data) =>{ 
              if(data)
              {
                field.onChange(data.id);
                setSelectedIssuer(data.id);
              }
              else{
                field.onChange(null);
                setSelectedIssuer(null);
              }
            }} // Update the form value
          />
        )}
      />
      <TextField 
                    select
                    name="dividendType"
                    // {...register("p_settlementstatus", { required: true })}
                    label="Select Dividend Type"
                    variant="outlined"
                    className="input-field"
                    fullWidth
                    onChange={(e)=>{setSelectedDividendType(e.target.value)}}
                    style={{ marginLeft: "5px",width:"20%" }}
                    value={selectedDividendType}
                  >
                      <MenuItem value="Cash"
                      style={{ fontSize: 13,fontWeight:"bold" }}
                      >
                        Cash
                      </MenuItem>
                      <MenuItem value="Stock"
                      style={{ fontSize: 13,fontWeight:"bold" }}
                      >
                        Stock
                      </MenuItem>
              </TextField> 
            <Controller
              name="fromDate"
              control={control}
              render={({ field: { onChange, value } })  => (
                <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale={'en-gb'}>
                  <DatePicker sx={{marginLeft:"5px",width:"20%"}}
                  label="From Date"
                    onChange={(date) => {
                      date = date ? date.format("YYYY/MM/DD") : ""
                    setSelectedFromDate(date);
                  }
                      }
                    renderInput={(params) => (
                      <TextField 
                        fullWidth
                        {...params}
                        label="Settlement Date"
                        variant="outlined"
                      />
                    )}
                  />
                </LocalizationProvider>
              )}
            />
            <Controller
              name="toDate"
              control={control}
              render={({ field: { onChange, value } })  => (
                <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale={'en-gb'}>
                  <DatePicker sx={{marginLeft:"5px",width:"20%"}}
                  label="To Date"
                    onChange={(date) => {
                      date = date ? date.format("YYYY/MM/DD") : ""
                    setSelectedToDate(date);
                  }
                      }
                    renderInput={(params) => (
                      <TextField 
                        fullWidth
                        {...params}
                        label="Settlement Date"
                        variant="outlined"
                      />
                    )}
                  />
                </LocalizationProvider>
              )}
            />

               
            <Button style={{marginLeft:"20px"}} variant="contained" disabled = {selectedIssuer=="" || selectedDividendType=="" ? true : false} color="primary" onClick={handleShowData}>Search</Button>
          </Box>
          {
            isLoading 
            ?
            <LoaderBar/>
            :
            <MaterialReactTable table={table} />
            
          }
        </>);
};

export default SettledDividend;
