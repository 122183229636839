import ReplyIcon from '@mui/icons-material/Reply';
import { TextField } from "@mui/material";
import Backdrop from '@mui/material/Backdrop';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Fade from '@mui/material/Fade';
import Modal from '@mui/material/Modal';
import React, { useEffect, useMemo, useState } from 'react';
import Swal from 'sweetalert2';
import axios from '../../utils/axios';

import { MenuItem } from "@material-ui/core";
import DoneAllIcon from '@mui/icons-material/DoneAll';
import {
  Autocomplete,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Tooltip
} from '@mui/material';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import 'dayjs/locale/en-gb';
import { Controller, useForm } from "react-hook-form";

import BorderColorIcon from '@mui/icons-material/BorderColor';
import EditIcon from '@mui/icons-material/Edit';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import 'dayjs/locale/en-gb';
import { download, generateCsv, mkConfig } from 'export-to-csv';
import {
  MaterialReactTable,
  useMaterialReactTable
} from 'material-react-table';
import LoaderBar from "../../components/LoaderBar/LoaderBar";
import PageTitle from "../../components/PageTitle/PageTitle";
import './ReadyToSettled.css';
  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '60%',
    bgcolor: 'background.paper',
    border: '2px solid #eee',
    boxShadow: '2px 2px 2px 2px #eee',
    borderRadius:"10px",
    p: 4
  };

const ReadyToSettled = () => {

  const initialValue = {
    p_id:0,
    p_settlementStatus:0,
    bankName:"",
    bandId:"",
    bankAccountNo:"",
    p_settlementNo:"",
    p_settlementDate:"",
    p_remarks:"",
    p_updatedBy: "",
    p_dp_id:0,
    p_stock_unit_price:0.0
  
  }
  
  let settlementstatus = [{id:1,label:"Ready to settle"},{id:2,label:"Settled"}]
  
let claimtypelist =[{id:1,label:"Self"},{id:2,label:"Authorized Person/Power of Attorney"},{id:3,label:"Successor/Nominee"}] 
  const [settlementStatus,setSettlementStatus] = useState(0);
  const [bankList, setBankList] = useState([]);
  const [branchList, setBranchList] = useState([]);
  const [selectedBank,setSelectedBank] = useState("");
  const [data,setData] = useState([]);
  const [dividendType,setDividendType] = useState([]);
  const { register, control,setValue } = useForm({});
  const [submitValue,setSubmitValue] = useState(initialValue);
const [branch,setBranch] = useState({});
const [show,setShow] = useState(false);
const [dpList,setDPList] = useState([]);
const [selectedHouse,setSelectedHouse] = useState(null);
const [ExcelData, setExcelData] = useState([]);
const [isLoading,setIsLoading] = useState(false);
const [open,setOpen] = useState(false);
const [BulkUpdateInfo,setBulkUpdateInfo] = useState({
  p_settlementStatus:0,
  p_settlementNo:"",
  p_settlementDate:"",
  p_remarks:""
})
const enableAdd = BulkUpdateInfo.p_settlementStatus !== 0 && BulkUpdateInfo.p_settlementNo!=="" && BulkUpdateInfo.p_settlementDate !=="";

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '60%',
  bgcolor: 'background.paper',
  border: '2px solid #eee',
  boxShadow: '2px 2px 2px 2px #eee',
  borderRadius:"10px",
  p: 4
};

const csvConfig = mkConfig({
  fieldSeparator: ',',
  decimalSeparator: '.',
  useKeysAsHeaders: true,
});
const handleExportData = table => {
  const csv = generateCsv(csvConfig)(ExcelData);
  download(csvConfig)(csv);
};

const handleClose = () => {
  setShow(false);
};



  const handleSubmit = (id,table) =>{
    if(submitValue.p_settlementStatus===2 && submitValue.p_settlementDate!=="")
    {
      if(submitValue.bankName!=="")
      {
        if(submitValue.bandId!=="" && submitValue.bankAccountNo!=="")
        {
          axios.put('/settlement',submitValue)
          .then((res)=>{
            if(res.data.statusCode===200)
            {
              setSelectedBank("");
              setSubmitValue(initialValue);
              setBranch({});
              setSettlementStatus(0);
              setValue('p_bank_id',null);
              setValue('p_bankName',null);
              Swal.fire({
                customClass: {
                  container: 'my-swal'
                },
                icon: 'success',
                title: 'Dividend has been settled successfully',
                showConfirmButton: false,
                timer: 3000
              })
              // data.filter(item => item.settlement_id !== id);
              // setData(data.filter(item => item.settlement_id !== id))
              setData((prevData) => prevData.filter((item) => item.settlement_id !== id));
              table.setEditingRow(null);
              // getReadyToSettledList();
            }
          })
          .catch((err)=>{
            console.log(err);
          })
        }
        else{
          Swal.fire({
            customClass: {
              container: 'my-swal'
            },
            icon: 'warning',
            title: 'Please select Branch Name and Provide Account No',
            showConfirmButton: true,
            // timer: 3000
          })
        }
      }
      else{
        axios.put('/settlement',submitValue)
          .then((res)=>{
            if(res.data.statusCode==200)
              {
                setSelectedBank("");
                setSubmitValue(initialValue);
                setBranch({});
                setSettlementStatus(0);
                Swal.fire({
                  customClass: {
                    container: 'my-swal'
                  },
                  icon: 'success',
                  title: 'Dividend has been settled successfully',
                  showConfirmButton: false,
                  timer: 3000
                })
                // setData(data.filter(item => item.settlement_id !== id))
                setData((prevData) => prevData.filter((item) => item.settlement_id !== id));
                table.setEditingRow(null);
              }
          })
          .catch((err)=>{
            console.log(err);
          })
      }
      
    }
    else{
      Swal.fire({
        customClass: {
          container: 'my-swal'
        },
        icon: 'warning',
        title: 'Please Change the Settlement Status and Provide Settlement Date',
        showConfirmButton: true,
        // timer: 3000
      })
    }
    
  }
  
 

  const getBankList = async () =>
    {
      await axios.get('/bank/get-all-bank-names')
      .then((res)=>{
        // console.log(res.data.payload);
        setBankList(res.data.payload);
      } )
      .catch((err)=>console.log(err));
    }
    
    const getReadyToSettledList = async () =>{
      setIsLoading(true);
      try
      {
        const dividenTypeApiHit = await axios.get('/inward-dividend/get-dividend-types')  
        let dividenTypeApiRes = dividenTypeApiHit.data.payload; 
        const response = await axios.get('/settlement/get-by-settlement-status/1')
           let res = response.data.payload;
        // console.log(response);
        let modifiedData =[]
        res.map(item =>{
          const rowWithFetchedId = dividenTypeApiRes.find(row => row.id === item.investor_dividend_type_id);
          const labelToShow = rowWithFetchedId ? rowWithFetchedId.investorDividendType+ "("+rowWithFetchedId.dividendType+")"  : item.investor_dividend_type_id;
          const cashorstock = rowWithFetchedId ? rowWithFetchedId.dividendType  : item.investor_dividend_type_id;
          const claimTypeText = claimtypelist.find(row=>row.id===item.claimtype)?.label ;
          const createdatdateformat = new Date(item.createdat).toISOString().split("T")[0];
          const YearList = item.years.join(", ");
          delete item.years;
          modifiedData.push({...item,investorDividendType:labelToShow,yearlist:YearList,dividendType:cashorstock,claimTypeText:claimTypeText,createdat:createdatdateformat})
        })
        setData(modifiedData);
        setIsLoading(false);
        setDividendType(dividenTypeApiRes);
        
      }
      catch(error)
      {
        setIsLoading(false);
        console.log(error);
        Swal.fire({
                    icon: 'error',
                    title: `Error fetching ready to settled list: ${error.response?.data?.message || error.message}`,
                    showConfirmButton: true,
                    // timer: 3000
                  })
      }
      
    }

  const getBranchList = async (bank) =>
    {
      let object = {bankName:`${bank}`}
      await axios.post('/bank/get-all-banks-by-name',object)
      .then((res)=>{
        // console.log(res.data.payload);
        setBranchList(res.data.payload);
      } )
      .catch((err)=>console.log(err));
    }
    const getdplist = () =>{
        axios.get('/bank/get-all-dp-names')
        .then((res)=>{
          setDPList(res.data.payload);
        })
        .catch((err)=>console.log(err));
      }
  
let accounts = [{id:1,label:"Joint Account"},{id:2,label:"Individual Account"}] ;

      const handleChangeSettlementStatus = (event) =>{
        setSettlementStatus(event.target.value);
        setSubmitValue(prevState => ({
          ...prevState,
          p_settlementStatus:event.target.value
        }));
      }
      const handleBulkUpdate = (rows,event) => {
        event.preventDefault();
        Swal.fire({
          title: 'Do you want to save the changes?',
          showDenyButton: true,
          confirmButtonText: 'Yes',
          denyButtonText: 'No',
          icon: 'question',
          customClass: {
            container: 'my-swal'
          },
        })
        .then((result) => {
          if (result.isConfirmed) {
            rows.map((investor)=>{
              const payload = {
                p_id: investor.original.settlement_id,
                p_settlementStatus: BulkUpdateInfo.p_settlementStatus,
                bankName: investor.original.bankname,
                bandId: investor.original.bankid,
                bankAccountNo: investor.original.bankaccountno,
                p_settlementNo: BulkUpdateInfo.p_settlementNo,
                p_settlementDate: BulkUpdateInfo.p_settlementDate,
                p_remarks: BulkUpdateInfo.p_remarks,
                p_updatedBy: "",
                p_dp_id: investor.original.dpid,
                p_stock_unit_price: investor.original.stockunitprice,
              };
              if(payload.p_settlementStatus===2 && payload.p_settlementDate!=="")
                {
                  if(payload.bankName!=="")
                  {
                    if(payload.bandId!=="" && payload.bankAccountNo!=="")
                    {
                      axios.put('/settlement',payload)
                      .then((res)=>{
                        if(res.data.statusCode===200)
                        {
                          setSelectedBank("");
                          setSubmitValue(initialValue);
                          setBranch({});
                          setSettlementStatus(0);
                          setValue('p_bank_id',null);
                          setValue('p_bankName',null);
                          Swal.fire({
                            customClass: {
                              container: 'my-swal'
                            },
                            icon: 'success',
                            title: 'Dividend has been settled successfully',
                            showConfirmButton: false,
                            timer: 3000
                          })
                          setOpen(false);
                          // data.filter(item => item.settlement_id !== id);
                          // setData(data.filter(item => item.settlement_id !== investor.original.settlement_id))
                          setData((prevData) => prevData.filter((item) => item.settlement_id !== investor.original.settlement_id));
                          // getReadyToSettledList();
                        }
                      })
                      .catch((err)=>{
                        console.log(err);
                      })
                    }
                    else{
                      Swal.fire({
                        customClass: {
                          container: 'my-swal'
                        },
                        icon: 'warning',
                        title: 'Please select Branch Name and Provide Account No',
                        showConfirmButton: true,
                        // timer: 3000
                      })
                    }
                  }
                  else{
                    axios.put('/settlement',payload)
                      .then((res)=>{
                        if(res.data.statusCode==200)
                          {
                            setSelectedBank("");
                            setSubmitValue(initialValue);
                            setBranch({});
                            setSettlementStatus(0);
                            Swal.fire({
                              customClass: {
                                container: 'my-swal'
                              },
                              icon: 'success',
                              title: 'Dividend has been settled successfully',
                              showConfirmButton: false,
                              timer: 3000
                            })
                            setOpen(false);
                            // setData(data.filter(item => item.settlement_id !== investor.original.settlement_id))
                            setData((prevData) => prevData.filter((item) => item.settlement_id !== investor.original.settlement_id));
                          }
                      })
                      .catch((err)=>{
                        console.log(err);
                      })
                  }
                  
                }
                else{
                  Swal.fire({
                    customClass: {
                      container: 'my-swal'
                    },
                    icon: 'warning',
                    title: 'Please Change the Settlement Status and Provide Settlement Date',
                    showConfirmButton: true,
                    // timer: 3000
                  })
                }
            })
            }})
          
            .catch((e)=>{
              Swal.fire('Oops! ',e.message,'', 'error')
            })
       
        
        
      }

      const handleBulkEditModal = (rows) => {
        
        return(
              <Modal
                    aria-labelledby="transition-modal-title"
                    aria-describedby="transition-modal-description"
                    open={open}
                    onClose={()=>setOpen(false)}
                    closeAfterTransition
                    BackdropComponent={Backdrop}
                    slots={{ backdrop: Backdrop }}
                    slotProps={{
                    backdrop: {
                        timeout: 500,
                    }}}
                   style={{maxHeight: "80vh",  overflow: "auto", textAlign:"center", borderRadius:"10px"
                   }}
                   >
                    <Fade in={open}>
                    <Box sx={style} style={{textAlign:"center",width:"100%"}} >
                        <h3>Bulk Update From Ready To Settle - Settled Status </h3>
                        <Box style={{width:"100%",border:"2px solid #eee", maxHeight: "20vh", overflow: "auto",}}>
                          <h4>Selected Investors: {rows.length}</h4>
                          {
                            rows.map((investors)=>{
                              return(
                                <h6 style={{margin:"5px",border:"2px dashed #eee",textAlign:"center"}}> {investors.original.issuer_name} : {investors.original.name} / ({investors.original.bo_id || investors.original.folio_number})</h6>
                              )
                            })
                          }
                        </Box>
                        <form>
                            
                            <Box className="form-control" style={{textAlign:"center"}}>
                            
                            <TextField 
                                    select
                                    focused
                                    required
                                    name="p_settlementstatus"
                                    defaultValue={1}
                                    // {...register("p_settlementstatus", { required: true })}
                                    label="Select Settlement Status"
                                    variant="outlined"
                                    className="input-field"
                                    fullWidth
                                    onChange={(event)=>{
                                      setSettlementStatus(event.target.value);
                                      setBulkUpdateInfo(prevState => ({
                                          ...prevState,
                                          p_settlementStatus:event.target.value
                                        }));
                                    }}
                                    style={{ margin: "5px" }}
                                  >
                                    {settlementstatus.map((option, index) => (
                                      <MenuItem
                                        key={index}
                                        value={option.id}
                                        style={{ fontSize: 13,fontWeight:"bold" }}
                                      >
                                        {option.label}
                                      </MenuItem>
                                    ))}
                              </TextField>
                              <TextField  style={{margin:"5px"}}
                              focused fullWidth
                              type="number"
                              defaultValue={null}
                                onChange={(e)=>{
                                  setBulkUpdateInfo(prevState => ({
                                    ...prevState,
                                    p_settlementNo:e.target.value
                                  }));
                                }}
                              label="Settlement Serial" />
                              <Controller
                              name="p_settlementdate"
                              control={control}
                              focused
                              render={({ field: { onChange, value } })  => (
                                <LocalizationProvider dateAdapter={AdapterDayjs} fullWidth adapterLocale={'en-gb'} focused>
                                  <DatePicker sx={{margin:"5px",width:"100%"}} fullWidth
                                  required= {settlementStatus==2 ? true : false}
                                  label="Settlement Date"
                                  disableFuture
                                  {...register("p_settlementdate", { required: settlementStatus===2 ? true : false })}
                                    onChange={(date) => {
                                      date = date ? date.format("YYYY/MM/DD") : "";
                                      setBulkUpdateInfo(prevState => ({
                                        ...prevState,
                                        p_settlementDate: date
                                      }));
                                      
                                    onChange(date);
                                  
                                  }
                                    
                                    
                                      }
                                    renderInput={(params) => (
                                      <TextField 
                                        fullWidth
                                        {...params}
                                        focused
                                        required
                                        label="Settlement Date"
                                        variant="outlined"
                                        style={{ margin: "5px 0" }}
                                      />
                                    )}
                                  />
                                </LocalizationProvider>
                              )}
                            />

                          <TextField  style={{margin:"5px"}} fullWidth label="Remarks" focused
                                onChange={(e)=>{
                                  setBulkUpdateInfo(prevState => ({
                                    ...prevState,
                                    p_remarks:e.target.value
                                  }));
                                }}
                              variant="outlined" multiline rows={5} defaultValue={null}/> 
                              <Button type="submit" disabled={!enableAdd} onClick={(e)=>{handleBulkUpdate(rows,e)}}  variant="contained" color="success" style={{color:"white"}}><BorderColorIcon/> Bulk Update </Button>
                            
                            &nbsp;&nbsp;
                            <Button type="button" onClick={()=>setOpen(false)} variant="contained" color="info" style={{color:"white"}}><ReplyIcon/> Go Back</Button>
                            </Box>
                        </form>
                    </Box>
                    </Fade>
                </Modal>
              
            
        )
      }

  const columns = useMemo(
    //column definitions...
    () => [
      {
        accessorKey: 'bo_id',
        header: 'BOID',
      },
      {
        accessorKey: 'folio_number',
        header: 'Folio Number',
      },
      {
        accessorKey: 'name',
        header: 'Investor Name',
      },
      {
        accessorKey: 'issuer_name',
        header: 'Issuer',
        size: 50,
      },
      
      {
        accessorKey: 'investorDividendType',
        header: 'Investor Dividend Type',
        // Cell: ({ cell }) => {
        //   const id = cell.getValue(); // Get the raw id value
        //   const rowWithFetchedId = dividendType.find(row => row.id === id);
        //   const labelToShow = rowWithFetchedId ? rowWithFetchedId.investorDividendType+ "("+rowWithFetchedId.dividendType+")"  : id;
        //   return labelToShow // Map to label or show 'Unknown' if not found
        // },
      },
      {
        accessorKey: 'total_amount',
        header: 'Amount',
      },
      {
        accessorKey: 'createdby',
        header: 'Entry By',
      },
      {
        accessorKey: 'createdat',
        header: 'Entry Date',
      },
    ],
    [],
    //end
  );

  const table = useMaterialReactTable({
    columns,
    data:data,
    // enableExpandAll: false, //disable expand all button
    editDisplayMode: 'modal',
    enableEditing: true,
    enableRowSelection: true,
    enableStickyHeader: true,
    enableStickyFooter: true,
    muiPaginationProps: {
      rowsPerPageOptions: [5, 10, 15, 20, 25, 30, 50, 100, data.length]
    },
    muiTableContainerProps: {

      style: { maxHeight: '60vh' } 

  },
    getRowId: (row) => row.id,
    renderTopToolbarCustomActions: ({ table }) => (
      <Box
        sx={{
          display: 'flex',
          gap: '16px',
          padding: '8px',
          flexWrap: 'wrap',
        }}
      >
        <Button
          //export all data that is currently in the table (ignore pagination, sorting, filtering, etc.)
          onClick={()=>handleExportData(table)}
          startIcon={<FileDownloadIcon />}
          disabled={table.getPrePaginationRowModel().rows.length === 0}
          variant="contained"
          color="info"
        >
          Export All Data
        </Button>
        <Button
          //export all data that is currently in the table (ignore pagination, sorting, filtering, etc.)
          variant="contained"
          onClick={()=>{setOpen(true);}}
          startIcon={<BorderColorIcon />}
          disabled={table.getSelectedRowModel().rows.length <= 1}
          color="warning"
        >
          Bulk Edit
        </Button>
        {open && handleBulkEditModal(table.getSelectedRowModel().rows)}
      </Box>
    ),
    // muiDetailPanelProps: () => ({
    //   sx: (theme) => ({
    //     backgroundColor:
    //       theme.palette.mode === 'dark'
    //         ? 'rgba(255,210,244,0.1)'
    //         : 'rgba(0,0,0,0.1)',
    //   }),
    // }),
    //custom expand button rotation
    // muiExpandButtonProps: ({ row, table }) => ({
    //   onClick: () => {table.setExpanded({ [row.id]: !row.getIsExpanded() })
    //   setSubmitValue(prevState => ({
    //     ...prevState,
    //     p_id:row.original?.settlement_id,
    //     p_settlementStatus:row.original?.settlementstatus,
    //     p_settlementNo:row.original?.settlementno,
    //     bankName:row.original?.bankName || "",
    //     p_remarks:row.original?.remarks,
    //     bankAccountNo:row.original?.bankaccountno || null,
    //     bandId:row.original?.bankid || null,
    //     p_dp_id:row.original.dp_id || 0,
    //     p_stock_unit_price: row.original.stockunitprice || 0.0

    //   }))
    //   setSelectedBank(row.original?.bankname || "");
    //   setBranch({});
    // }, //only 1 detail panel open at a time
    //   sx: {
    //     transform: row.getIsExpanded() ? 'rotate(180deg)' : 'rotate(-90deg)',
    //     transition: 'transform 0.2s',
    //   },
    // }),
    // //conditionally render detail panel
    // renderDetailPanel: ({ row }) =>
      
    //   row.original.name ? (
    //     <>
    //     <Box
    //       sx={{
    //         display: 'grid',
    //         gap: 2, // Adds spacing between grid items
    //         gridTemplateColumns: {
    //          xs: '1fr', // 1 column on extra-small screens
    //          sm: '1fr', // 2 columns on small screens and up
    //          md: '1fr 1fr', // 2 columns on medium screens and up
    //         },
    //         width: '100%',
    //         margin: '0 auto', // Centers the form on the screen
    //         overflow:"visible",
    //         textAlign:"center",
    //         padding:"100px"
    //       }}
    //     >
          
    //     </Box>
    //           <Box  display="flex" justifyContent="center" mt={2} sx={{ width: '100%' }}>
    //             <Button  color="primary" onClick={()=>handleSubmit(row.original.settlement_id)} variant="contained" endIcon={<DoneAllIcon />}>UPDATE DIVIDEND RECORD </Button>
    //           </Box>
    //     </>
    //   ) : null,
    muiEditRowDialogProps: {
      // Apply custom styles to the modal using props
      sx: {
        "& .MuiPaper-root": {
          bgcolor: "background.paper",
          border: "2px solid #eee",
          boxShadow: "2px 2px 2px 2px #eee",
          borderRadius: "10px",
          // p: 4,
        },
      },
    },

    renderRowActions: ({ row, table }) => (
      <Box sx={{ display: 'flex', gap: '1rem' }}>
        <Tooltip title="Edit">
          <IconButton disabled={table.getSelectedRowModel().rows.length >1} onClick={() => {table.setEditingRow(row);setShow(true)}}>
            <EditIcon />
          </IconButton>
        </Tooltip>
      </Box>
    ),
   
    renderEditRowDialogContent: ({ table, row, internalEditComponents }) => (

      <>
        
        
          <DialogTitle  >Update To Ready To Settle</DialogTitle>
          <DialogContent >
          <form>
          <div className="form-control" >
          <TextField  style={{margin:"5px"}} fullWidth readOnly  label="Issuer Name"  value={row.original.issuer_name}/> 
          <TextField  style={{margin:"5px"}} fullWidth readOnly label="BOID" value={row.original.bo_id}/> 
          <TextField  style={{margin:"5px"}} fullWidth readOnly label="Folio No" value={row.original.folio_number}/>
          <TextField  style={{margin:"5px"}} fullWidth readOnly label="Name" value={row.original.name}/> 
          <TextField  style={{margin:"5px"}} fullWidth readOnly label="NID" value={row.original.nid}/> 
          <TextField  style={{margin:"5px"}} fullWidth readOnly label="Passport" value={row.original.passport}/> 
          <TextField  style={{margin:"5px"}} fullWidth readOnly label="Investor Dividend Type" value={row.original.investorDividendType}/> 
          <TextField  style={{margin:"5px"}} fullWidth readOnly label="Claim Type" value={row.original.claimTypeText}/>
          {
            row.original.claimtype == 2 || row.original.claimtype == 3
            ?
            <>
            <TextField  style={{margin:"5px"}} fullWidth readOnly label="Claimant Name" value={row.original.claimantname}/>
            <TextField  style={{margin:"5px"}} fullWidth readOnly label="Claimant NID" value={row.original.claimantnid}/>
            </>
            :
            null
          }
          
          <TextField  style={{margin:"5px"}} fullWidth readOnly label="Account Type" value={accounts.find(item=>item.id===row.original.account_type)?.label}/>
          <TextField  style={{margin:"5px"}} fullWidth readOnly label="Second Investor's Name" value={row.original.shareholder_name}/>
          <TextField  style={{margin:"5px"}} fullWidth readOnly label="Second Investor's NID" value={row.original.sholdernid}/>
          <TextField  style={{margin:"5px"}} fullWidth readOnly label="Second Investor's Passport" value={row.original.sholderpassport}/>
              <TextField  style={{margin:"5px"}} fullWidth readOnly label="Application Date" value={new Date(row.original.application_date).toISOString().split("T")[0]}/>
              <TextField  style={{margin:"5px"}} fullWidth readOnly label="Claim Recieved Date" value={new Date(row.original.receive_date).toISOString().split("T")[0]}/>
              

              {
                row.original.dividendType == "Cash"
                ?
                <>
                {
                row.original.bankname == "" || row.original.bankname==null ? 
                
                <Controller
              
                name="p_bankName"
                control={control}
                render={({ field }) => (
                  <Autocomplete
                    {...field}
                    options={bankList}
                    style={{ margin: "5px"}}
                    getOptionLabel={(option) => `${option.bankName}`}
                    renderInput={(params) => (
                      <TextField 
                      focused
                      placeholder="Select Bank"
                        {...params}
                        label="Select Bank"
                        variant="outlined"
                         fullWidth
                      />
                    )}
                    onChange={(_, data) => {
                      if(data)
                      {
                        field.onChange(data.bankName); 
                        setSelectedBank(data.bankName);
                          setSubmitValue(prevState => ({
                            ...prevState,
                            bankName:data.bankName,
                          }));
                        getBranchList(data.bankName);
                      }
                      else{
                        field.onChange(null);
                      }

                      
                    }} // Update the form value
                  />
                )}
              />
            :
            <TextField  style={{margin:"5px"}} fullWidth readOnly label="Bank Name" value={row.original.bankname}/>
                
            }
              {
                row.original.branchname=="" || row.original.branchname==null ?
                
              <Controller
                name="p_bank_id"
                control={control}
                
                render={({ field }) => (
                  <Autocomplete
                    {...field}
                    options={branchList}
                    
                    style={{ margin: "5px"}}
                    defaultValue={row.original.bankid || null}
                    getOptionLabel={(option) => `${option.branchName} (routingNo:${option.routingNo})`}
                    renderInput={(params) => (
                      <TextField 
                        {...params}
                        label="Select Branch"
                        variant="outlined"
                        required={selectedBank !== ""}
                        placeholder="Select Branch"
                        focused fullWidth
                      />
                    )}
                    onChange={(_, data) => {
                      if(data)
                      {
                        field.onChange(data.id);
                        setBranch(data); 
                        setSubmitValue(prevState => ({
                          ...prevState,
                          bandId:data.id
                        }))
                      }
                      else{
                        field.onChange(null);
                      }

                      
                    }} 
                  />
                )}
              />
              :
                            <TextField  style={{margin:"5px"}} fullWidth readOnly label="Branch Name" value={row.original.branchname}/>
              
            }
              {
                row.original.routingno=="" || row.original.routingno==null ?
                
                <TextField  style={{margin:"5px"}} fullWidth label="Routing No"
              // required={selectedBank !== ""}
              focused
              readonly
              value={branch ? branch.routingNo:""}
              defaultValue={row.original.routingno || null}
              />
            :
            <TextField  style={{margin:"5px"}} fullWidth readOnly label="Routing No" value={row.original.routingno}/>
                
            }
              {
                row.original.bankaccountno=="" || row.original.bankaccountno==null ?
               
                
                <TextField focused style={{margin:"5px"}} fullWidth label="Bank Account No"
              required={selectedBank !== ""}
              value={row.original.bankaccountno || null}
              onChange={(event)=>{
                setSubmitValue(prevState => ({
                  ...prevState,
                  bankAccountNo:event.target.value
                }))
              }}
              />
            :
            <TextField  style={{margin:"5px"}} fullWidth readOnly label="Bank Account No" value={row.original.bankaccountno}/>
            }
                </>
                :
                row.original.dividendType == "Stock"
                ?
                <>
                     {
                row.original.dpname=="" || row.original.dpname==null ?
                
                <Controller
                name="p_dp_id"
                control={control}
                render={({ field }) => (
                  <Autocomplete
                    {...field}
                    {...register("p_dp_id")}
                    options={dpList}
                    getOptionLabel={(option) => `${option.dpName} (${option.dpId})`}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Select DP House"
                        variant="outlined"
                        fullWidth
                        focused
                        style={{ margin: "5px 0", width: "100%" }} fullWidth
                      />
                    )}
                    onChange={(_, data) => {
                      
                      if(data)
                      {
                        field.onChange(data.id); 
                        setSelectedHouse(data.id);
                        setSubmitValue(prevState => ({
                          ...prevState,
                          p_dp_id:data.id
                        }));
                      }
                      else{
                        field.onChange(null);
                        setSelectedHouse(0);
                      }
                      
                    }} // Update the form value
                  />
                )}
              />
              :
                            <TextField  style={{margin:"5px"}} fullWidth readOnly label="DP House" value={row.original.dpname || ""}/>
              
            }
              {
                row.original.stockunitprice=="" || row.original.stockunitprice==null ?
                
                <TextField name="p_stock_unit_price"
                  // required={selectedHouse >0 || selectedHouse < 0  ? true : false}
                  focused 
                  fullWidth variant="outlined" 
                  label="Stock Unit Price" 
                  style={{ margin: "5px", width: "100%" }}
                  onChange={(e)=>{
                    setSubmitValue(prevState => ({
                      ...prevState,
                      p_stock_unit_price:e.target.value
                    }));
                  }}
                {...register("p_stock_unit_price")}
                />

            :
            <TextField  style={{margin:"5px"}} fullWidth readOnly label="Stock Unit Price" value={row.original.stockunitprice}/>
                
            }
                </>
                :
                null
              }

              <TextField  style={{margin:"5px"}} fullWidth readOnly label="Total Amount" value={row.original.total_amount}/>
              <span></span>
              <TextField  style={{margin:"5px"}} fullWidth readOnly label="Years" variant="outlined" multiline rows={5} value={row.original.yearlist}/>

                <TextField 
                    select
                    focused
                    required
                    name="p_settlementstatus"
                    defaultValue={row.original.settlementstatus || 1}
                    // {...register("p_settlementstatus", { required: true })}
                    label="Select Settlement Status"
                    variant="outlined"
                    className="input-field"
                     fullWidth
                    onChange={handleChangeSettlementStatus}
                    style={{ margin: "5px" }}
                  >
                    {settlementstatus.map((option, index) => (
                      <MenuItem
                        key={index}
                        value={option.id}
                        style={{ fontSize: 13,fontWeight:"bold" }}
                      >
                        {option.label}
                      </MenuItem>
                    ))}
              </TextField>


              <TextField  style={{margin:"5px"}}
              focused fullWidth
              type="number"
              defaultValue={row.original.settlementno || null}
                onChange={(e)=>{
                  setSubmitValue(prevState => ({
                    ...prevState,
                    p_settlementNo:e.target.value,
                    p_id:row.original.settlement_id,


                  }))
                }}
              label="Settlement Serial" />
              <Controller
              name="p_settlementdate"
              control={control}
              focused
              render={({ field: { onChange, value } })  => (
                <LocalizationProvider dateAdapter={AdapterDayjs} fullWidth adapterLocale={'en-gb'} focused>
                  <DatePicker sx={{margin:"5px",width:"100%"}} fullWidth
                  required= {settlementStatus==2 ? true : false}
                  label="Settlement Date"
                  disableFuture
                  {...register("p_settlementdate", { required: settlementStatus==2 ? true : false })}
                    onChange={(date) => {
                      date = date ? date.format("YYYY/MM/DD") : "";
                      setSubmitValue(prevState => ({
                      ...prevState,
                      p_settlementDate: date
                    }))
                    onChange(date);
                  
                  }
                    
                    
                      }
                    renderInput={(params) => (
                      <TextField 
                        fullWidth
                        {...params}
                        focused
                        required
                        label="Settlement Date"
                        variant="outlined"
                        style={{ margin: "5px 0" }}
                      />
                    )}
                  />
                </LocalizationProvider>
              )}
            />
              
              
              <TextField  style={{margin:"5px"}} fullWidth label="Remarks" focused
                onChange={(e)=>{
                  setSubmitValue(prevState => ({
                    ...prevState,
                    p_remarks:e.target.value
                  }))
                }}
              variant="outlined" multiline rows={5} defaultValue={row.original.remarks}/> 
                           
                            </div>
                            
                        </form>
                        </DialogContent>
                        <DialogActions style={{justifyContent:"center",padding:"5px"}}>
        

          {/* <MRT_EditActionButtons variant="text" table={table} row={row} /> */}
          <div className="form-control" style={{textAlign:"center"}}>
          <div> <p>The fields can be updated are : <strong>Settlement Status, Settlement Serial ID, Settlement Date, Remarks</strong></p></div>
         <div>
         <Button type="submit" /*disabled={!enableAdd}*/ onClick={()=>{handleSubmit(row.original.settlement_id,table);}} variant="contained" color="success" style={{color:"white"}}><DoneAllIcon/> UPDATE DIVIDEND RECORD </Button>
             
         </div>
                           
                            </div>
        </DialogActions>
        
        </>   
       
                    
    )

  });
const getExcelData = () => {
  axios.get('/settlement/get-by-settlement-status-for-excel/1')
  .then((res)=>{
    let response = res.data.payload;
    setExcelData(response);
  })
  .catch((e)=>{
    console.log(e);
  })
}

  useEffect(() => {
    
    getReadyToSettledList();
    getExcelData();
    getBankList();
   getdplist();
  
  
  }, [])

  return(
        <>
          <PageTitle title={"Settle Status Update Dashboard"}></PageTitle>
          {/* <Box>
            <Button variant="contained" color="primary" onClick={handleShowData}> {show ? "Hide Data" : "See Data"}</Button>
          </Box> */}
          
          {
             isLoading
             ?
            <LoaderBar/>
            :
            <MaterialReactTable  table={table} />
          }

          
        </>);
};

export default ReadyToSettled;