import { Grid, MenuItem } from "@material-ui/core";
import { Autocomplete, Button, TextField } from '@mui/material';
import { default as React, useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { Link } from "react-router-dom";
import Swal from 'sweetalert2';
import PageTitle from "../../../components/PageTitle/PageTitle";
import axios from '../../../utils/axios';

const IssuerWiseUndefineAmountIncrease = () => {

const [issuerList,setIssuerList] = useState([]);
const [undefinedAmount,setUndefinedAmount] = useState(0.0);
const [issuerYear,setIssuerYear] = useState(""); 
// eslint-disable-next-line
const {  formState: { errors },control,reset  } = useForm();

const [submitValue,setSubmitValue] = useState({
issueruid:"",
inwarddividendtype:"",
remainingundefinedamount:undefinedAmount,
newundefinedadd:0.0,
totalundefinedcalculated:0.0,
dividendyear:""
});

let isFormValid = submitValue.issueruid !== "" && submitValue.newundefinedadd > 0 && submitValue.inwarddividendtype!=="" && submitValue.remainingundefinedamount>=0 && submitValue.dividendyear!=="";

const changeHandler = (e) =>{
  setSubmitValue( prevValues => {
    return { ...prevValues,[e.target.name]: e.target.value}
  })
  if(e.target.name === 'newundefinedadd')
  {
    let restamount = parseFloat(submitValue.remainingundefinedamount) + parseFloat(e.target.value)
    setSubmitValue(prevValues => {
      return { ...prevValues,totalundefinedcalculated:parseFloat(restamount),[e.target.name]: parseFloat(e.target.value)}
    })
  }
  if(e.target.name==='inwarddividendtype')
  {
    getUndefinedAmountbyissuer(e.target.value);
  }
}
const submitHandler = () => {
  
  const formData = new FormData();
  const userid = JSON.parse(localStorage.getItem("user-id"));
  formData.append('issueruid',submitValue.issueruid);
  formData.append('inwarddividendtype',submitValue.inwarddividendtype);
  formData.append('remainingundefinedamount',submitValue.remainingundefinedamount);
  formData.append('newundefinedadd',submitValue.newundefinedadd);
  formData.append('totalundefinedcalculated',submitValue.totalundefinedcalculated);
  formData.append('userid',userid)
  submitValue.userid = userid;
    Swal.fire({
      title: 'Do you want to save the changes?',
      showDenyButton: true,
      confirmButtonText: 'Yes',
      denyButtonText: 'No',
      icon: 'question',

    })
    .then((result) => {
      if (result.isConfirmed) {
        axios.post('/investor-informations/update-unidentified-cash-stock',submitValue)
        .then((res)=>{
          Swal.fire(res.data.payload.fu_issuer_wise_undefined_cash_stock_increase, '', 'success')
          setUndefinedAmount(0);
          reset(); 
          setSubmitValue({
            issueruid:"",
            inwarddividendtype:"",
            remainingundefinedamount:undefinedAmount,
            newundefinedadd:0.0,
            totalundefinedcalculated:0.0,
            dividendyear:""
            })
        })
        .catch((e)=>{
          Swal.fire('Oops! ',e.message,'', 'error')
        })
        
      } else if (result.isDenied) {
        Swal.fire('Changes are not saved', '', 'info')
      }
    })
  

}


  const getIssuer = async () =>{
     await axios.get('./issuer/get-info')
    .then((res)=>{

      setIssuerList(res?.data?.payload);

    })
    .catch((err) =>{
      console.log(err)
    })
  }
  

  const getyearlistbyissuer = (issueruuid)=>{

    
    if(issueruuid)
    axios.get(`/issuer-year-format/${issueruuid}`)
    .then((res)=>{
      let response = res?.data?.payload;
      let query = response.find(i=>i.year === "NA");
      query ? setIssuerYear("NA") : setIssuerYear(""); 
      
    })
    .catch((err) =>{
      console.log(err.response.data.message)
    })
    
  }

  const getUndefinedAmountbyissuer = (inwarddividendtype)=>{

      axios.get(`/inward-dividend/get-unidentified-amount-by-issuer/${submitValue.issueruid}?dividend_type=${inwarddividendtype}`)
      .then((res)=>{
        if(res.data.payload && res.data.statusCode === 200)
        {
          Swal.fire({
                icon: 'success',
                showConfirmButton:false,
                title: 'Remaining Undefined amount restored from the database',
                timer: 3000
              }).then(()=>{
                if(inwarddividendtype === "Cash")
                  {
                    let cash = parseFloat(res?.data?.payload[0]?.totalcash);
                    setUndefinedAmount(cash || 0);
                    setSubmitValue( prevValues => {
                      return { ...prevValues,remainingundefinedamount:parseFloat(cash || 0),newundefinedadd:0,totalundefinedcalculated:0}
                    })
                  }
                  else
                  {
                    let stock = parseFloat(res?.data?.payload[0]?.totalstock);
                    setUndefinedAmount(stock || 0);
                    setSubmitValue( prevValues => {
                      return { ...prevValues,remainingundefinedamount:parseFloat(stock || 0),newundefinedadd:0,totalundefinedcalculated:0}
                    })
                  }
              })
          
          

        }
        else{
            Swal.fire("Oops! Some error Occurred!",'', 'error')
        }
        
        
        
      })
      .catch((err) =>{
        Swal.fire({
          icon: 'error',
          title: `Oops! Some error Occurred!" `,
          text:err.response.data.message,
          showConfirmButton: true,
          // timer: 3000
        })
      })
  }


  useEffect(() => {
   getIssuer();
  }, [])
  


  return (
    <div style={{boxShadow:"2px 2px 2px 2px gray",padding:"50px",borderRadius:"5px"}}>
        <PageTitle title={"Issuer wise undefined Cash/Stock Increase"}></PageTitle>

        <Grid  container spacing={1}>
          <Grid style={{padding:"20px"}} lg={12} md={12} sm={12} xs={12}>
          <div style={{marginBottom:"10px"}}> 
                <Controller
                      name="p_id"
                      control={control}
                      render={({ field, fieldState: { error } }) => {
                        const { onChange, value, ref } = field;
                        return (
                          <>
                            <Autocomplete
                                value={
                                  value
                                    ? issuerList.find((option) => option.id === value) ?? null
                                    : null
                                }
                                disableClearable
                                getOptionLabel={(option) => `${option.name} (${option.tradecode})`}
                                onChange={(event, data) => {
                                  onChange(data ? data.id : "");
                                  setSubmitValue( prevValues => {
                                    return { ...prevValues,issueruid: data.id}
                                  })
                                  getyearlistbyissuer(data.id);
                                }}
                                options={issuerList}
                                renderInput={(params) => (
                                  <TextField
                                    {...params}
                                    required
                                    name="issueruid"
                                    label={"Select Issuer"}
                                    inputRef={ref}
                                    error={!!error}
                                    helperText={error ? error.message : ""}
                                  />
                                )}
                              />
                            {error ? (
                              <span style={{ color: "red" }}>{error.message}</span>
                            ) : null}
                          </>
                        );
                      }}
                    />  
                </div>
                <div style={{marginBottom:"10px"}}> 
                  
                    <Controller
                        name="inwarddividendtype"
                        control={control}
                        render={({ field: { onChange, value } }) => (
                      <TextField
                        select
                        required
                        fullWidth
                        name="inwarddividendtype"
                        value={submitValue.inwarddividendtype}
                        label="Select Dividend Type"
                        variant="outlined"
                        className="input-field"
                        onChange={(event, data) => {
                          if(submitValue.issueruid==="")
                          {
                            Swal.fire({
                              icon: 'danger',
                              title: 'Please Select Issuer Id First.',
                              showConfirmButton: true,
                              // timer: 3000
                            })
                            return;
                          }
                          else
                          changeHandler(event);
                          
                        }}
                      >
                        <MenuItem value="Cash" >Cash</MenuItem>
                        <MenuItem value="Stock" >Stock</MenuItem>
                      </TextField>
                        )}/>  
                  </div>

                  <div style={{marginBottom:"10px"}}> 
                  <TextField id="remainingundefinedamount" fullWidth label={submitValue.inwarddividendtype==="Stock" ? "Remaining Undefined Stock" : "Remaining Undefined Amount"} value={undefinedAmount}/>
               </div>
               <div  style={{marginBottom:"10px"}}> 
                <TextField required fullWidth name="newundefinedadd" onChange={changeHandler} value={submitValue.newundefinedadd} id="outlined-basic" type="number" label={submitValue.inwarddividendtype==="Stock" ? "Add Undefine Stock" : "Add Undefine Amount(in BDT)"} variant="outlined" />
                </div>
                <div style={{marginBottom:"10px"}}> 
                  <TextField fullWidth name="totalundefinedcalculated" id="outlined-basic" type="number" value={submitValue.totalundefinedcalculated} label={submitValue.inwarddividendtype==="Stock" ? "Total Undefined Stock" : "Total Undefined Amount(in BDT)"} variant="outlined" />
                </div>
                <div style={{marginBottom:"10px"}}>
                  {
                    issuerYear === "NA"
                    ? 
                    <Controller
                        name="inwarddividendtype"
                        control={control}
                        render={({ field: { onChange, value } }) => (
                      <TextField
                        select
                        required
                        fullWidth
                        name="dividendyear"
                        value={submitValue.dividendyear}
                        label="Select Dividend Year"
                        variant="outlined"
                        className="input-field"
                        onChange={(event, data) => {
                          if(submitValue.issueruid==="")
                          {
                            Swal.fire({
                              icon: 'danger',
                              title: 'Please Select Issuer Id First.',
                              showConfirmButton: true,
                              // timer: 3000
                            })
                            return;
                          }
                          else
                          changeHandler(event);
                          
                        }}
                      >
                        <MenuItem value="NA" >NA</MenuItem>
                      </TextField>
                        )}/>
                        :
                        <h4 style={{color:"red",textAlign:"center"}}>Select Issuer Year / <Link to="/app/issuer/yearDefine">Define NA against the respective issuer.</Link></h4>
                  } 
                
                </div>
          </Grid>
        </Grid>


            <Grid  style={{width:"80vw",justifyContent:"center"}} container spacing={1} >
              <Grid item>
              <Button
              style={{margin:'20px'}}
              variant="contained"
              color="primary"
              type="submit"
              size="large"
              position="center"
              disabled = {!isFormValid}
              onClick={submitHandler}
            >
              Submit
            </Button>
              </Grid>
            
            </Grid>
    </div>
  )
}

export default IssuerWiseUndefineAmountIncrease;